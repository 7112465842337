@import "~styles/store/variables.scss";

.container {
  form {
    max-width: 20em; } }

.header {
  color: $black;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold; }

.input {
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  padding: 7px 16px 9px 16px;
  background: $white;
  background: $pink-light;
  border: 3px solid $white-2;
  transition: all 0.3s 0.02s ease;
  border-radius: 3px;
  -webkit-appearance: none;
  line-height: 23px;

  &.heightAuto {
    height: auto; }

  &:hover, &:focus {
    background-color: $white-3;
    border-color: $pink-light-3; }

  &::placeholder {
    color: $pink-3;
    transition: all 0.3s 0.02s ease; } }

.fieldContainer {
  margin-top: 7px;

  & > span {
    color: $black; } }

.requestSent {
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  color: $black;
  margin: 21px 0; }
