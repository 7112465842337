@media screen and (max-width: $smallDesktop) {
    .wrap {
        width: 980px; }

    .on980, .only980 {
        display: block; }

    .hide980 {
        display: none; }

    h1, .h1 {
        font-size: 42px;
        line-height: 50px; }

    h2, .h2 {
        font-size: 36px;
        line-height: 46px; }

    header {
        padding: 10px 0 30px 0; }

    .top {
        padding: 0 0 20px 0; }

    .top_nav ul li {
        padding: 0 36px 0 0; }

    .main_nav {
        padding: 2px 0 0 20px; }

    .nav {
        width: 980px;
        top: 98px;
        &:before {
            width: 246px; }

        &:after {
            left: 492px; } }
    .top_search {
        width: 200px;
        margin: 2px 0 0 20px; }

    .top_tel {
        padding: 10px 0 0 20px; }

    .top_icons > div {
        margin: 0 0 0 20px; }

    #content {
        padding: 0 0 40px 0; }

    .breadc {
        padding: 0 0 25px 0; }

    .prod_title {
        width: 76%;
        padding: 0 0 5px 0; }

    .prod_lc {
        width: 24%; }

    .prod_lc a {
        margin: 0 0 0 20px; }

    .prod_l {
        width: 390px; }

    .prod_img_big {
      img {
        width: 220px; } }

    .prod_img_bigs {
        width: 320px; }

    .prod_imgs {
        width: 60px;
        height: 427px;

        &:before {
            width: 60px; }

        .slick-prev {
            left: 17px; }

        .slick-next {
            left: 17px; } }

    .prod_r {
        width: 570px; }

    .prod_bi {
        width: 265px; }

    .prod_by {
        padding: 25px 20px; }

    .prod_colors_t {
        padding: 0 0 12px 0; }

    .prod_tabs {
        // padding: 50px 0 20px 0
        padding: 0 0 20px 0; }
    .tab_pane {
        padding: 30px 0 0 0; }

    .relat_prods {
        padding: 0 0 20px 0;

        .title {
            padding: 0; } }

    .slick-dots {
        padding: 30px 0 0 0; }

    .prod_list {
        width: 236px;
        height: 412px;
        margin: 0 12px 12px 0;
        padding: 25px 14px;

        &.product_list_purchased {
            height: 502px; } }

    .prod_lists_big .prod_list:nth-child(3n) {
	      margin: 0 12px 12px 0; }

    .prod_lists_big .prod_list:nth-child(4n) {
        margin: 0 0 12px 0; }

    .prods_slide .prod_list {
        margin: 0 auto; }

    footer {
        padding: 40px 0 30px 0; }

    .nav_bot {
        width: 245px;
        margin: 0 30px 0 0;
        padding: 0 0 45px 0; }

    .bot_apps {
        width: 155px;
        padding: 0 0 40px 0; }

    .bot_tel {
        padding: 0; }

    .bot_soc {
        padding: 0 102px 0 0; }

    .bot_paykeeper {
        width: 220px;
        padding: 0 102px 0 0; } }
