@import "~styles/variables";
@import "~styles/store/variables";
@import "~styles/store/_helpers";

.slider {
  margin: 0 0 52px 0;

  .slick-prev {
    left: -2px;
    border-radius: 0 2px 2px 0; }

  .slick-next {
    right: -2px;
    border-radius: 2px 0 0 2px; }

  .slick-dots {
    width: 100%;
    padding: 0;
    position: absolute;
    left: 0px;
    bottom: 30px;

    li {
      button {
        background: #fff;
        opacity: 0.3;

        &:hover {
          opacity: 1; } }

      &.slick-active button {
        opacity: 1; } } } }

.top-slider {
  & a {
    height: 410px; } }

.slide {
  border-radius: 3px;
  position: relative;

  &:before {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #160013 0%, rgba(22, 0, 19, 0) 100%);
    opacity: 0.3;
    border-radius: 3px;
    position: absolute;
    top: 0px;
    left: 0px; }

  .slide_background {
    pointer-events: none;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover; } }

.slide_t {
  padding: 0 0 46px 0;
  font-size: 60px;
  line-height: 60px;
  font-family: Archive;

  span {
    display: block;
    padding: 0 0 20px 0;
    font-size: 30px;
    line-height: 30px; } }

.slide_price {
  float: left;
  height: 45px;
  padding: 0 12px;
  background: #FFF;
  border-radius: 2px;
  color: #A0148E;
  font-size: 30px;
  line-height: 45px;
  font-weight: bold; }

.slide_price_old {
  float: left;
  padding: 0 0 0 16px;
  font-size: 30px;
  line-height: 45px;
  font-weight: bold;
  text-decoration-line: line-through; }

.home_cats {
  padding: 0 0 28px 0;

  .slick-dots {
    bottom: auto;
    padding-top: 20px;

    li {

      button {
        background: #A0148E;
        opacity: 0.3;

        &:hover {
          opacity: 1; } }

      &.slick-active button {
        opacity: 1; } } }

  .title {
    padding: 0 0 14px 0; } }

.cats_slider {
  position: relative;
  margin: 0 -11px;

  .slick-arrow {
    top: 105px; } }

@media (max-width: 1260px) {
  .cats_slider {
    margin: 0; } }

.cats_slide a {
  display: block;
  width: 283px;
  height: 210px;
  margin: 0 auto;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  color: #FFF;
  font-size: 22px;
  line-height: 33px;
  font-weight: bold;
  text-align: center;

  &:before {
    content: " ";
    display: block;
    background: linear-gradient(180.17deg, rgba(82, 0, 71, 0) 39.47%, rgba(82, 0, 71, 0.85) 67.19%);
    opacity: 0.5;
    border-radius: 3px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 20;
    transition: all 0.3s 0.02s ease; }

  &:hover:before {
    opacity: 0.8; } }

.cats_slide_img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  border-radius: 3px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s 0.02s ease; }

.cats_slide a:hover .cats_slide_img {
  transform: scale(1.15); }

.cats_slide_t {
  display: block;
  width: 90%;
  position: absolute;
  left: 5%;
  bottom: 20px;
  z-index: 30; }

.home_prods {
  padding: 0 0 30px 0;

  .title {
    padding: 0 0 4px 0; } }

.povids {
  padding: 0 0 58px 0;

  .title {
    padding: 0 0 27px 0; } }

.povids_over {
  position: relative;
  overflow: hidden;

  .slick-list {
    height: 358px;

    @media screen and ( max-width: $mobile) {
      height: auto; } }

  &.open {
    height: auto; } }

.povids_show {
  display: block;
  width: 100%;
  height: 110px;
  background: url('~images/prod_imgs_next.svg') no-repeat center, linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  position: absolute;
  bottom: 0px;
  left: 0px;
  cursor: pointer;

  &:hover {
    background: url('~images/prod_imgs_next_h.svg') no-repeat center, linear-gradient(180deg, rgba(255, 249, 254, 0) 0%, #FFF9FE 69.27%); } }

.povids_slider {
  position: relative;

  .slick-prev {
    top: 107px;
    left: 0px; }

  .slick-next {
    top: 107px;
    right: 0px; }

  .slick-dots {
    position: relative;
    z-index: 1408; } }

.povids_dots .slick-dots {
  padding: 10px 0 0 0; }

.povid {
  padding: 0 10px;
  transition: all 0.3s 0.02s ease;
  line-height: 23px;
  text-align: center; }

.povid_t {
  padding: 0 0 16px 0;
  font-size: 22px;
  line-height: 27px;
  font-weight: bold;

  a {
    display: block;
    padding: 93px 0 0 0;
    color: #2D0027;
    background-repeat: no-repeat;
    background-position: center 0;

    &:hover {
      color: #A0148E; } } }

.povid ul li {
  padding: 5px 0;

  a {
    color: #968093;

    &:hover {
      color: #D866C9; } } }

.home_prods_bot {
  padding: 0 0 62px 0;

  .slick-track {
    display: flex;
    justify-content: center;
    align-content: center; } }

.home_prod {
  width: 283px; }

.home_prod_big {
  width: 592px;
  margin: 0 26px; }

.home_prod a {
  display: block;
  height: 422px;
  border-radius: 3px;
  position: relative;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &:before {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #160013 0%, rgba(22, 0, 19, 0) 100%);
    opacity: 0.3;
    border-radius: 3px;
    position: absolute;
    top: 0px;
    left: 0px; }

  &:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    background: #D866C9;
    opacity: 0;
    border-radius: 3px;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: all 0.3s 0.02s ease; }

  &:hover:after {
    opacity: 0.2; } }

.home_prod_t {
  display: block;
  width: 90%;
  position: absolute;
  top: 45px;
  left: 5%;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  font-family: Archive; }

.home_prod_big .home_prod_t {
  top: 42px;
  font-size: 30px;
  line-height: 30px; }

.home_prod_t span {
  display: block;
  padding: 10px 0 0 0;
  font-size: 30px;
  line-height: 36px; }

.home_prod_big .home_prod_t span {
  padding: 0;
  font-size: 60px;
  line-height: 60px; }

.home_prod_p {
  display: block;
  padding: 5px 12px;
  background: #FFFFFF;
  border-radius: 2px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  color: #A0148E;
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  white-space: nowrap; }

.brends {
  padding: 0 0 26px 0; }

.brends_col {
  float: left;
  width: 20%;
  text-align: center; }

.brends_t {
  padding: 0 0 30px 0;
  color: #2D0027;
  line-height: 23px;
  font-weight: bold; }

.brend {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
  padding: 0 10px 16px 10px;

  a:hover {
    opacity: 0.5; }

  img {
    max-height: 67px;
    vertical-align: bottom; } }

@media screen and ( max-width: $smallDesktop) {
  .slider {
    margin: 0 0 50px 0;

    .slick-prev {
      left: 0px; }

    .slick-next {
      right: 0px; } }

  .top-slider {
    & a {
      height: 398px; } }

  .home_cats .title {
    padding: 0; }

  .cats_slide a {
    width: 236px;
    font-size: 20px;
    line-height: 30px; }

  .home_prods .title {
    padding: 0; }

  .povids {
    padding: 0 0 50px 0;

    .title {
      padding: 0; } }

  .povid_t a {
    padding: 85px 0 0 0; }

  .home_prods_bot {
    padding: 0 0 50px 0; }

  .home_prod_big {
    width: 374px;
    margin: 0 20px;

    .home_prod_t {
      font-size: 28px;
      line-height: 28px;

      span {
        font-size: 50px;
        line-height: 50px; } } }

  .brends_t {
    padding: 0 0 20px 0; }

  .brend {
    padding: 0 7px 14px 7px; } }

@media screen and (max-width: $tablet) {
  .slider {
    margin: 0 0 30px 0; }

  .top-slider {
    & a {
      height: 378px; } }

  .slide_t {
    padding: 0 0 40px 0;
    font-size: 50px;
    line-height: 50px;

    span {
      font-size: 28px;
      line-height: 28px; } }

  .povids {
    padding: 0 0 30px 0; }

  .povid_t a {
    padding: 80px 0 0 0; }

  .home_prods_bot {
    padding: 0 0 30px 0; }

  .home_prod {
    width: 236px; }

  .home_prod_big {
    margin: 0 5px;

    .home_prod_t {
      top: 45px;
      font-size: 20px;
      line-height: 20px;

      span {
        padding: 10px 0 0 0;
        font-size: 30px;
        line-height: 36px; } } }

  .brends {
    padding: 0 0 20px 0; }

  .brend {
    height: 50px;
    padding: 0 5px 10px 5px;

    img {
      max-height: 50px; } } }

@media screen and (max-width: $mobile) {
  .slider {
    margin: 0 0 25px 0; }

  .top-slider {
    & a {
      height: 180px; } }

  .slide_t {
    padding: 0 0 9px 0;
    font-size: 18px;
    line-height: 18px;

    span {
      padding: 0 0 10px 0;
      font-size: 13px;
      line-height: 13px; } }

  .slide_price {
    float: none;
    display: inline-block;
    height: 29px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 29px; }

  .slide_price_old {
    float: none;
    padding: 0;
    font-size: 13px;
    line-height: 20px; }

  .slider .slick-dots {
    bottom: 9px; }

  .home_cats {
    padding: 0 0 26px 0; }

  .cats_slider {
    margin: 0 -19px 0 0;

    &:before {
      content: " ";
      display: block;
      width: 50px;
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), #FFFFFF);
      opacity: 0.9;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 100; }

    .slick-arrow, .slick-dots {
      display: none !important; } }

  .cats_slide {
    padding: 0 10px 0 0;

    a {
      width: 99px;
      height: auto;
      margin: 0;
      color: #A0148E;
      font-size: 12px;
      line-height: 17px;

      &:before {
        display: none; } } }

  .cats_slide_img {
    display: block;
    width: 99px;
    height: 80px;
    margin: 0 0 7px 0;
    position: relative; }

  .cats_slide_t {
    width: 100%;
    position: relative;
    left: 0;
    bottom: auto; }

  .povids_over {
    height: auto;
    overflow: visible; }

  .povids_show {
    display: none; }

  .povids_slider {
    margin: 0 -19px 0 0;

    &:before {
      content: " ";
      display: block;
      width: 50px;
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), #FFFFFF);
      opacity: 0.9;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 100; }

    .slick-arrow {
      display: none !important; } }

  .povids_dots .slick-dots {
    display: none !important; }

  .povid {
    padding: 0 6px; }

  .povid_t {
    padding: 0;
    font-size: 13px;
    line-height: 20px;

    a {
      padding: 41px 0 0 0; } }

  .povid1 .povid_t a {
    background-size: 34px auto; }

  .povid2 .povid_t a {
    background-size: 33px auto;
    background-position: center 7px; }

  .povid3 .povid_t a {
    background-size: 22px auto;
    background-position: center 3px; }

  .povid4 .povid_t a {
    background-size: 32px auto; }

  .povid5 .povid_t a {
    background-size: 33px auto;
    background-position: center 5px; }

  .povid ul {
    display: none; }

  .home_prods_slider {
    .slick-prev {
      left: 0px; }

    .slick-next {
      right: 0px; }

    .slick-dots {
      width: 100%;
      position: absolute;
      left: 0px;
      bottom: 7px;
      z-ondex: 100;

      li {
        button {
          background: #fff;
          opacity: 0.3;

          &:hover {
            opacity: 1; } }

        &.slick-active button {
          opacity: 1; } } } }

  .home_prod {
    width: 280px;
    margin: 0 auto;

    a {
      height: 180px; } }

  .home_prod_t, .home_prod_big .home_prod_t {
    width: 80%;
    top: 35px;
    left: 10%;
    font-size: 13px;
    line-height: 13px; }

  .home_prod_t span, .home_prod_big .home_prod_t span {
    padding: 7px 0 0 0;
    font-size: 18px;
    line-height: 18px; }

  .home_prod_p {
    padding: 0 12px;
    bottom: 39px;
    font-size: 18px;
    line-height: 29px; }

  .brends {
    padding: 0 0 10px 0; }

  .brends_col {
    float: none;
    width: auto; }

  .brends_t {
    display: none; }

  .brend {
    float: left;
    box-sizing: border-box;
    width: 33.33%;
    height: 50px;
    padding: 0 7px 15px 7px;

    img {
      max-height: 35px; } } }
