@media screen and (max-width: $mobile) {
    .prod_price_old {
        .old {
          font-size: 13px; }

        .discount {
          font-size: 10px;
          display: inline;
          width: 15px;
          height: 15px; } }

    .wrap {
        width: 100%; }

    .on320 {
        display: block; }

    .only760 {
        display: none; }

    img {
        &.alignright, &.right, &.alignleft, &.left {
            float: none;
            display: block;
            margin: 0 0 10px 0; } }

    .video {
        position: relative;
        overflow: hidden;
        height: 0;
        padding-bottom: 54.15%;

        iframe, object, embed {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0; } }

    .entry_table {
        width: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch; }

    .entry img {
        width: auto !important;
        height: auto !important; }

    [class*="col-"] {
        float: none;
        width: auto;
        padding: 0; }

    body {
        font-size: 12px;
        line-height: 20px; }

    p, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .title {
        margin: 0 0 20px 0; }

    .entry {
        table, form, ul, ol, hr {
            margin: 0 0 20px 0; } }

    blockquote {
        margin: 0 0 20px 0; }

    h1, .h1, .title {
        font-size: 18px;
        line-height: 22px; }

    h2, .h2 {
        font-size: 17px;
        line-height: 18px; }

    h3, .h3 {
        font-size: 16px;
        line-height: 18px; }

    h4, .h4 {
        font-size: 15px;
        line-height: 18px; }

    h5, .h5 {
        font-size: 14px;
        line-height: 18px; }

    h6, .h6 {
        font-size: 13px;
        line-height: 18px; }

    .title {
        font-size: 18px;
        line-height: 18px;
        text-align: center; }

    .btn {
        width: 100%;
        min-height: 36px;
        padding: 7px 20px;
        font-size: 13px;
        line-height: 20px; }

    header {
        height: 129px;
        padding: 0 0 14px 0; }

    .top {
        height: 48px;
        padding: 0;
        background: $pink-light; }

    .show_nav_mob, .top_tel_mob {
        display: block; }

    .top_city {
        float: none;
        position: absolute;
        top: 59px;
        right: 20px; }

    .show_city.open:before {
        padding: 0 20px;
        left: -20px; }

    .top_city_drop {
        display: none;
        width: 260px;
        padding: 22px 20px 20px 20px;
        top: 89px;
        left: auto;
        right: 0px; }

    .top_city_t {
        display: none; }

    .city_search {
        margin: 0 0 15px 0; }

    .top_city_ul {
        max-height: 367px;
        padding: 0 4px 0 0;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 9px;
            height: 3px; }

        &::-webkit-scrollbar-button {
            display: none; }

        &::-webkit-scrollbar-track, &::-webkit-scrollbar-track-piece {
            background-color: $pink-light; }

        &::-webkit-scrollbar-thumb {
            height: 70px;
            background: linear-gradient(to right, $pink-light 33%, $pink-light 34%, $pink 35%, $pink 67%, $pink-light 68%, $pink-light);
            border-radius: 2px;
            cursor: pointer; }

        &::-webkit-scrollbar-corner, &::-webkit-resizer {
            background-color: $pink; }

        li {
            margin: 0;
            border-bottom: 2px solid $pink-light;

            a {
                padding: 9px 0; } } }

    .top_soc, .top_nav {
        display: none; }

    .header {
        padding: 0; }

    .logo {
        margin: 0 20px;
        width: 90px;
        height: 20px;
        padding: 9px 0 12px 0; }

    .nav {
        position: relative;
        width: auto;
        top: 48px;
        text-align: left;
        padding: 14px 20px 25px 20px;
        border-radius: 0 3px 3px 3px;
        &::before {
          position: relative; }
        &::after {
          position: relative; } }

    .top_search {
        margin: 0 20px;
        float: none;
        width: auto;

        &.open .search {
            top: 41px; } }

    .search_result {
        top: 95px;
        padding: 7px 0 13px 0;

        ul li a {
            padding: 4px 20px;
            font-size: 13px; } }

    .search_clear {
        padding: 10px 20px 0 20px; }

    .show_nav, .top_tel, .top_like, .top_comp {
        display: none; }

    .top_icons > div {
        text-align: center;
        float: none;
        margin: 0;
        position: absolute;

        > a {
            height: 17px;
            padding: 31px 0 0 0;
            line-height: 11px; } }

    .top_user {
        left: 50%;
        top: -48px;
        width: 25%; }

    .top_icons > .top_user > a {
        padding: 0;
        padding-top: 31px;
        background-position: center 4px;
        margin: 0; }

    .top_cart {
        left: 75%;
        top: -48px;
        width: 25%;

        > a {
            background-position: center 6px;

            span {
                display: inline-block;
                left: auto;
                top: 4px;
                margin-left: -23px; } } }

    #content {
        padding: 0 19px 20px 19px; }

    .breadc_back {
        font-size: 13px;
        display: block; }

    .prod_lc {
        display: inline-flex;
        width: 100%;
        padding: 0;
        margin-bottom: 5px;

        a {
          &:nth-child(2n) {
            margin-left: 20px; } } }

    .prod_title {
        width: 100%;

        h1 {
            line-height: 20px;
            font-size: 18px;
            margin-bottom: 5px; } }

    .prod_l {
        float: none;
        width: auto;
        padding: 0 0 12px 0; }

    .prod_img_bigs {
        float: none;
        width: auto; }

    .prod_img_big {
        a {
            margin: 0 7px;
            opacity: 0.3;
            width: 126px; }

        &.slick-center a {
            opacity: 1; }

        img {
            width: auto;
            max-height: 60vh; } }

    .prod_imgs {
        display: none !important; }

    .prod_params {
        float: none;
        width: auto;
        margin: 0 0 6px 0;
        padding: 0;
        overflow: hidden;

        &.open {
            height: auto;
            padding: 0 0 50px 0; } }

    .prod_params_t {
        padding: 0 0 7px 0;
        font-size: 13px;
        line-height: 20px; }

    .prod_param_l {
        width: 50%; }

    .prod_param_r {
        width: 45%; }

    .prod_params_show {
        display: block; }

    .prod_bi {
        float: none;
        width: auto;
        padding: 0; }

    .prod_by {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
        padding: 9px 5px;
        margin: 0;
        background: $pink-light;
        box-shadow: 0px 0px 20px rgba(57, 0, 50, 0.2);
        border-radius: 0;
        position: fixed;
        left: 0px;
        bottom: 0px;
        z-index: 300;
        text-align: center; }

    .arrive_expected_at {
      display: inline-block;
      order: -1;
      font-size: 13px;
      line-height: 17px;
      vertical-align: bottom;
      margin-bottom: 0;
      margin-right: 7px; }

    .prod_price {
        order: 1;
        display: inline-block;
        vertical-align: bottom;
        width: 100%;
        height: 34px;
        padding: 0 10px;
        margin: 0 2px;
        border: 1px solid #F1DAEE;
        border-radius: 3px;
        font-size: 13px;
        line-height: 34px; }

    .prod_add {
        width: 65%;
        order: 2;
        display: inline-block;
        vertical-align: bottom;
        margin: 0 2px; }

    .btn_by {
        width: 100%;
        min-height: 36px;
        padding: 9px 10px;
        font-size: 13px;
        line-height: 18px;

        &:hover {
            background-color: #D866C9; }

        span {
            padding: 0 0 0 26px;
            background-size: 15px auto; } }

    .prod_ok {
        display: inline-block;
        width: 145px;
        margin: 0 2px; }

    .prod_ok_go {
        height: 36px;
        padding: 5px 40px 0 0;
        font-size: 11px;
        line-height: 13px;

        span {
            font-size: 11px;
            line-height: 13px; } }

    .btn_plus {
        width: 38px;
        height: 32px;
        top: 2px;
        right: 2px;
        font-size: 11px;
        line-height: 32px; }

    .prod_colors {
        margin: 0 -19px 0 0; }

    .prod_colors_ul {
        max-width: 100%;
        white-space: nowrap;
        position: relative;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            width: 0;
            height: 0px; }

        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none; }

    .prod_colors_t {
        padding: 0 0 10px 0;
        font-size: 13px;
        line-height: 20px; }

    .prod_color {
        float: none;
        display: inline-block;
        padding: 0 6px 0 0; }

    .prod_tabs {
        // padding: 20px 0 10px 0
        padding: 0 0 10px 0;
        margin: 0 -19px 0 0; }

    .tabs {
        max-width: 100%;
        white-space: nowrap;
        position: relative;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            width: 0;
            height: 0px; }

        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;

        li {
            margin: 0 14px 0 0;
            vertical-align: bottom;
            font-size: 13px;
            line-height: 22px;

            &:last-child {
                margin: 0 14px 0 0;
                vertical-align: bottom;
                font-size: 13px;
                line-height: 20px; }

            a {
                padding: 0 0 7px 0;

                span {
                    min-width: 11px;
                    height: 15px;
                    padding: 0 2px;
                    border-radius: 10px;
                    font-size: 10px;
                    line-height: 15px; } } } }

    .tab_pane {
        padding: 12px 19px 0 0; }

    .prod_text {
        height: 110px;
        margin: 0 0 6px 0;
        overflow: hidden;

        &.open {
            height: auto;
            padding: 0 0 20px 0; } }

    .prod_text_show {
        display: block; }

    .prod_rew {
        padding: 0 0 6px 0;
        margin: 0 0 7px 0; }

    .prod_rew_lv2 {
        margin-left: 30px; }

    .prod_rew_lv3 {
        margin-left: 60px; }

    .prod_rew_a {
        padding: 0 0 2px 0;
        font-size: 13px; }

    .prod_rew_d {
        padding: 0 0 2px 0;
        line-height: 19px; }

    .prod_rew_repl {
        padding: 5px 0; }

    .prod_rew_add {
        padding: 9px 0 25px 0; }

    .relat_prods {
        padding: 0 0 10px 0; }

    .prods_slider {
        margin: 0 -5px; }

    .slick-arrow {
        width: 30px;
        height: 28px;
        background-size: 10px auto; }

    .slick-prev {
        left: 5px; }

    .slick-next {
        right: 5px; }

    .slick-dots {
        height: 11px;
        padding: 14px 0 0 0;
        line-height: 8px; }

    .prod_imgs .slick-dots {
        padding: 8px 0 0 0; }

    .slick-dots li {
        padding: 0 3px;

        button {
            width: 6px;
            height: 6px; }

        &.slick-active button {
            width: 8px;
            height: 8px; } }

    .prod_list, .prod_list:nth-child(3n) {
        width: 135px;
        height: 272px;
        margin: 0 10px 10px 0;
        padding: 25px 10px;
        box-sizing: border-box;

        &:hover {
            border-color: #FCEBF9; } }

    .prod_lists_big .prod_list, .prod_lists_big .prod_list:nth-child(3n), .prod_lists_big .prod_list:nth-child(4n),
    .prod_lists .prod_list, .prod_lists .prod_list:nth-child(3n), .prod_lists .prod_list:nth-child(4n) {
        width: calc(33.3% - 6.5px);
        height: 272px;
        margin: 0 10px 10px 0;
        padding: 25px 10px;
        box-sizing: border-box;

        &:hover {
            border-color: #FCEBF9; }

        @media screen and (max-width: $smallMobile) {
            width: calc(50% - 5px); } }

    .prod_list:nth-child(2n), .prod_lists_big .prod_list:nth-child(2n), .prod_lists .prod_list:nth-child(2n) {
        margin: 0 10px 10px 0;

        @media screen and (max-width: $smallMobile) {
            margin: 0 0 10px 0; } }

    @media screen and (min-width: $smallMobile) {
        .prod_list:nth-child(3n), .prod_lists_big .prod_list:nth-child(3n), .prod_lists .prod_list:nth-child(3n) {
            margin: 0 0 10px 0; } }

    .prod_list.product_list_purchased, .prod_list.product_list_purchased:nth-child(3n), .prod_lists_big .prod_list.product_list_purchased:nth-child(4n), .prod_lists_big .prod_list.product_list_purchased:nth-child(3n) {
        height: 302px; }

    .prods_slide .prod_list {
        float: none;
        margin: 0 auto; }

    .prod_list_img {
        height: 112px;
        margin: 0 0 9px 0;

        img {
            max-height: 112px; } }

    .prod_list_t {
        height: 68px;
        margin: 0;
        font-size: 11px;
        line-height: 15px; }

    .prod_list_bot {
        width: calc(100% + 20px);
        margin-left: -10px;
        height: 55px;
        background: $pink-light;
        position: unset;
        left: 0;
        bottom: 0;
        justify-content: space-between; }

    .prod_list_price {
        float: left;
        height: 45px;
        padding: 0 11px;
        margin: 0;
        line-height: 45px; }

    .prod_list_price_old {
        float: left;
        padding: 8px 11px 6px 11px;
        margin: 0; }

    .prod_list_add {
        float: right;
        height: 45px; }

    .btn_by_sm {
        border-color: transparent; }

    .prod_list_comp {
        top: 11px;
        right: 11px; }

    .prod_list_like {
        top: 9px;
        right: 39px; }

    footer {
        padding: 25px 0 20px 0; }

    .nav_bot {
        float: none;
        width: auto;
        margin: (-1px) 20px 0 20px;
        padding: 13px 19px;
        border: 1px solid #EFE4ED; }

    .nav_bot1 {
        border-radius: 3px 3px 0 0; }

    .nav_bot3 {
        border-radius: 0 0 3px 3px; }

    .bot_apps {
        float: none;
        width: auto;
        padding: 19px 0 13px 0;
        text-align: center; }

    .bot_t {
        padding: 0;
        position: relative;
        font-size: 13px;
        line-height: 20px;

        &:before {
            content: " ";
            display: block;
            width: 10px;
            height: 7px;
            background: url("~images/bot_t.svg") no-repeat;
            position: absolute;
            top: 7px;
            right: 0px;
            transition: all 0.3s 0.02s ease; } }

    .nav_bot.open .bot_t:before {
        transform: rotate(180deg); }

    .bot_apps .bot_t {
        display: none; }

    .nav_bot {
        ul {
            display: none;
            padding: 6px 0 0 0; }

        &.open ul {
            display: block; }

        ul li {
            padding: 0 0 5px 0; } }

    .bot_app {
        display: inline-block;
        padding: 0 5px;

        img {
            max-width: 100px; } }

    .bot_tel {
        float: none;
        padding: 0 0 13px 0;
        font-size: 18px;
        text-align: center; }

    .bot_soc {
        float: none;
        padding: 0 0 8px 0;
        text-align: center; }

    .bot_paykeeper {
        width: 190px;
        float: none;
        padding: 0 0 12px 0;
        margin: auto;
        text-align: center; }

    .copy {
        float: none;
        font-size: 11px;
        text-align: center; }

    .mask_close {
        display: none !important; } }

.form_line {
    padding: 0 0 12px 0; }

.lab {
    padding: 0 0 5px 0;
    font-size: 13px;
    line-height: 20px; }

.flatpage_payment a {
  margin-top: 10px; }
