footer {
    flex-shrink: 0;
    padding: 44px 0 39px 0;
    background: $pink-light; }

.nav_bot {
    float: left;
    width: 270px;
    margin: 0 73px 0 0;
    padding: 0 0 55px 0;
    line-height: 23px; }

.nav_bot3 {
    margin: 0; }

.bot_apps {
    float: right;
    width: 240px;
    padding: 0 0 55px 0; }

.bot_t {
    padding: 0 0 18px 0;
    opacity: 0.5;
    color: $black;
    font-size: 22px;
    line-height: 33px;
    font-weight: bold; }

.nav_bot ul li {
    padding: 0 0 10px 0;

    a {
        opacity: 0.5;
        color: $black;

        &:hover {
            opacity: 1;
            color: #D866C9; } }

    &.active a {
        opacity: 1;
        color: #D866C9; } }

.bot_app {
    padding: 0 0 12px 0;

    a {
        opacity: 0.7;

        &:hover {
            opacity: 1; } }

    img {
        max-width: 130px;
        vertical-align: bottom; } }

.bot_tel {
    float: right;
    padding: 0 80px 0 0;
    font-size: 22px;
    line-height: 24px;
    font-weight: bold;

    a {
        opacity: 0.5;
        color: $black;

        &:hover {
            opacity: 1;
            color: $black; } } }

.bot_soc {
    float: right;
    padding: 0 116px 0 0; }

.bot_paykeeper {
    float: right;
    padding: 0 116px 0 0;
    width: 280px;
    height: 30px;
    background: url("~images/paykeeper-grey.png") no-repeat (center / contain);
    transition: all 0.3s 0.02s ease;

    &:hover {
      background-image: url("~images/paykeeper-color.png"); } }

.copy {
    float: left;
    opacity: 0.3;
    color: $black;
    line-height: 24px; }

#back_top {
    display: none;
    width: 53px;
    height: 53px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1000;
    transition: all 0.3s ease;
    background: #2D0027 url("~images/back_top.svg") no-repeat center;
    border-radius: 50%;
    opacity: 0.15; }

#back_top:hover {
    opacity: 0.3; }
