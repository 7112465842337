header {
  padding: 10px 0 35px 0;
  position: relative; }

.top {
  padding: 0 0 22px 0;
  line-height: 18px; }

.show_nav_mob {
  display: none;
  width: 25%;
  height: 17px;
  padding: 31px 0 0 0;
  background: url("~images/mob_nav.svg") no-repeat center 8px;
  border-radius: 3px 3px 0 0;
  position: absolute;
  top: 0px;
  left: 0px;
  color: #c672bb;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-image: url("~images/mob_nav_h.svg");
    color: #D866C9; }

  &.open {
    z-index: $overflow-all-z-index;
    background: #fff url("~images/mob_nav_close.svg") no-repeat center 9px !important; } }

.top_tel_mob {
  display: none;
  width: 25%;
  height: 17px;
  padding: 31px 0 0 0;
  background: url("~images/top_tel.svg") no-repeat center 6px;
  border-radius: 3px 3px 0 0;
  position: absolute;
  top: 0px;
  left: 25%;
  color: #c672bb;
  font-size: 9px;
  line-height: 11px;
  text-align: center;

  &:hover {
    background-image: url("~images/top_tel_h.svg");
    color: #D866C9; } }

.top_city {
  float: left; }

.show_city {
  display: inline-block;
  position: relative;
  z-index: $overflow-all-z-index;
  color: $pink;
  font-weight: bold;
  cursor: pointer;

  span {
    display: inline-block;
    padding: 0 13px 0 0;
    background: url("~images/show_city.svg") no-repeat right 6px;
    position: relative; }

  &.open {
    span {
      background-image: url("~images/show_city_a.svg"); }

    color: #968093;

    &:before {
      content: " ";
      display: block;
      width: 100%;
      height: 45px;
      padding: 0 20px;
      background: #fff;
      position: absolute;
      top: -10px;
      left: -20px; } } }

.top_city_drop {
  display: none;
  width: 100%;
  padding: 44px 20px 25px 20px;
  background: #fff;
  position: absolute;
  top: 45px;
  left: -20px;
  z-index: $overflow-all-z-index; }

.top_city_t {
  padding: 0 29px 28px 29px;
  line-height: 21px;

  span {
    padding: 0 8px 0 0;
    color: $black;
    font-weight: bold; } }

.city_search {
  max-width: 362px;
  margin: 0 29px 23px 29px; }

.top_city_ul {
  padding: 0 9px;

  li {
    margin: 0 0 6px 0; } }

@media (min-width: 760px) {
  .top_city_ul {
    column-width: 23%;
    column-count: 4;
    column-gap: 2%;
    line-height: 21px; } }

.top_city_b {
  font-weight: bold; }

.top_city_ul li a {
  display: block;
  padding: 12px 20px;
  color: $black;

  &:hover {
    background: $pink-light;
    color: $pink; } }

.top_soc {
  float: right; }

.soc a {
  display: inline-block;
  height: 18px;
  margin: 0 0 0 12px;
  opacity: 0.15; }

.bot_soc a, .nav_mob_soc a {
  height: 24px;
  margin: 0 7px; }

.soc a:hover {
  opacity: 1; }

.soc_vk {
  width: 23px;
  background: url("~images/soc_vk.svg") no-repeat (center / contain); }

.bot_soc .soc_vk, .nav_mob_soc .soc_vk {
  width: 30px; }

.soc_vk:hover {
  background-image: url("~images/soc_vk_h.svg"); }

.soc_fb {
  width: 19px;
  background: url("~images/soc_fb.svg") no-repeat (center / contain); }

.bot_soc .soc_fb, .nav_mob_soc .soc_fb {
  width: 24px; }

.soc_fb:hover {
  background-image: url("~images/soc_fb_h.svg"); }

.soc_in {
  width: 19px;
  background: url("~images/soc_in.svg") no-repeat (center / contain); }

.bot_soc .soc_in, .nav_mob_soc .soc_in {
  width: 24px; }

.soc_in:hover {
  background-image: url("~images/soc_in_h.svg"); }

.soc_tg {
  width: 21px;
  background: url("~images/soc_tg.svg") no-repeat (center / contain); }

.bot_soc .soc_tg, .nav_mob_soc .soc_tg {
  width: 27px; }

.soc_tg:hover {
  background-image: url("~images/soc_tg_h.svg"); }

.top_nav {
  float: right;
  padding: 0 4px 0 0;

  ul li {
    float: left;
    display: block;
    padding: 0 42px 0 0;

    a {
      color: #C0B3BE;

      &:hover {
        color: #D866C9; } }

    &.active a {
      color: #D866C9; } } }

.header {
  position: relative; }

.logo {
  float: left;
  width: 140px;
  height: 28px;
  padding: 10px 0 0 0;

  img {
    vertical-align: bottom; } }

.main_nav {
  float: left;
  padding: 2px 0 0 36px; }

.show_nav {
  display: block;
  height: 45px;
  padding: 0 27px 0 52px;
  position: relative;
  background: $pink url("~images/show_nav.svg") no-repeat 24px center;
  transition: all 0.3s 0.02s ease;
  border-radius: 3px;
  color: #fff;
  line-height: 45px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #D866C9; }

  &.open {
    z-index: $overflow-all-z-index;
    background: $pink-light url("~images/show_nav_close.svg") no-repeat 24px center;
    border-radius: 3px 3px 0 0;
    color: $pink; } }

.nav {
  width: 1210px;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: 100px;
  z-index: 10000;
  line-height: 23px;
  &:before {
    content: " ";
    display: block;
    width: 326px;
    height: 100%;
    background: $pink-light;
    border-radius: 3px 0 0 3px;
    position: absolute;
    top: 0px;
    left: 0px; }

  &:after {
    content: " ";
    display: block;
    width: 3px;
    height: 100%;
    background: $pink-light;
    position: absolute;
    top: 0px;
    left: 612px; } }

.top_search {
  float: left;
  width: 362px;
  margin: 2px 0 0 30px; }

.search {
  position: relative; }

.top_search.open .search {
  width: 100%;
  position: absolute;
  top: 2px;
  left: 0px;
  z-index: $overflow-all-z-index; }

.search_inp {
  width: 100%;
  height: 45px;
  padding: 7px 42px 9px 17px;
  background: $pink-light;
  border: 3px solid $white-2;
  box-sizing: border-box;
  border-radius: 3px;
  line-height: 23px;
  transition: all 0.3s 0.02s ease; }

.search:hover .search_inp {
  background: #FFEFFD;
  border: 3px solid #F8E3F3; }

.top_search.open .search_inp {
  background: #fff !important;
  border-color: #fff !important; }

.search_inp::placeholder {
  color: #d99dd1;
  transition: all 0.3s 0.02s ease; }

.search:hover .search_inp::placeholder {
  color: #b340a4; }

.search_sub {
  display: block;
  width: 39px;
  height: 39px;
  border-radius: 3px;
  background: url("~images/search.svg") no-repeat center;
  border: 0;
  transition: all 0.3s 0.02s ease;
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;

  &:hover {
    background: url("~images/search_h.svg") no-repeat center; } }

.search_result {
  width: 100%;
  padding: 12px 0 23px 0;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: 67px;
  left: 0;
  z-index: $overflow-all-z-index;
  line-height: 21px; }

.top_search.open .search_result {
  display: block; }

.search_result ul li {
  display: block;
  padding: 0 0 5px 0;

  a {
    display: block;
    padding: 12px 29px;
    color: $black;

    &:hover {
      background: $pink-light;
      color: $pink; } } }

.search_clear {
  padding: 20px 29px 0 29px;
  font-size: 13px;

  a {
    padding: 0 0 0 16px;
    background: url("~images/search_clear.svg") no-repeat 0px center;
    opacity: 0.6;
    color: $red;

    &:hover {
      opacity: 1;
      color: $red; } } }

.top_tel {
  float: left;
  padding: 10px 0 0 33px;
  color: $black;
  font-size: 24px;
  line-height: 29px;
  font-family: $font-secondary;

  a {
    color: $black;

    &:hover {
      color: #D866C9; } } }

.top_icons {
  float: right;
  > div {
    float: left;
    margin: 0 0 0 25px;
    position: relative;

    > a {
      display: block;
      height: 19px;
      padding: 33px 0 0 0;
      position: relative;
      border-radius: 3px 3px 0 0;
      color: #c672bb;
      font-size: 9px;
      line-height: 14px;

      &:hover {
        color: #D866C9; } } } }

.top_like > a {
  background: url("~images/top_like.svg") no-repeat center 11px;

  &:hover {
    background-image: url("~images/top_like_h.svg"); } }

.top_comp > a {
  background: url("~images/top_comp.svg") no-repeat center 12px;

  &:hover {
    background-image: url("~images/top_comp_h.svg"); } }

.top_icons > .top_user > a {
  padding: 33px 11px 0 11px;
  margin: 0 -11px;
  background: url("~images/top_user_login.svg") no-repeat center 8px;
  background-position-x: 9px;

  &:hover {
    background-image: url("~images/top_user_login_h.svg"); }

  &.is_authenticated {
    background: url("~images/top_user.svg") no-repeat center 8px;
    background-position-x: center;

    @media screen and ( max-width: $mobile) {
        background: url("~images/top_user.svg") no-repeat center 5px; }

    &:hover {
      background-image: url("~images/top_user_h.svg");
      background-position-x: center; } } }

.top_user > a {
  &.open {
    background-color: #fff;
    z-index: $overflow-all-z-index;

    &.is_authenticated {
      background-color: $white;
      z-index: $overflow-all-z-index; } } }

.top_cart > a {
  background: url("~images/top_cart.svg") no-repeat center 9px;

  &:hover {
    background-image: url("~images/top_cart_h.svg"); }

  span {
    display: block;
    width: 12px;
    height: 12px;
    background: $pink;
    border: 1px solid $white;
    transition: all 0.3s 0.02s ease;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    left: 15px;
    color: #fff;
    font-size: 9px;
    line-height: 12px;
    font-weight: bold;
    text-align: center; }

  &:hover span {
    background: #D866C9; } }
