@import "~styles/store/variables.scss";

.inCart {
  display: flex;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: $mobile) {
    justify-content: space-between; } }

.inCartSmall {
  justify-content: space-between; }

.inCartOrdered {
  margin-top: 10px; }

.inCartCountContainer {
  display: block;
  position: relative;
  vertical-align: bottom;
  height: 46px;
  background: $pink-light;
  overflow: hidden;
  width: 148px;

  @media screen and (max-width: $mobile) {
    width: 84px; } }

.inCartCount {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  vertical-align: bottom;
  padding: 0 10px;
  background: $pink-light;
  font-size: 13px;
  line-height: 46px;
  font-weight: bold;

  @media screen and (max-width: $mobile) {
    padding: 0; } }

.withoutAppend {
  @media screen and (max-width: $mobile) {
    width: 100%; } }

.inCartCountLoading {
  opacity: .5;
  cursor: progress; }

.inCartCountHideOnMobile {
  @media screen and (max-width: $mobile) {
    display: none; } }

@keyframes appendToCartLoading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

.appendOneToCartContainer {
  position: relative;
  border-radius: 2px;
  background-color: $white-shadow-2;
  width: 45px;
  height: 45px;
  overflow: hidden;

  @media screen and (max-width: $mobile) {
    background-color: $white;
    border-top: 3px solid;
    border-left: 3px solid;
    border-right: none;
    border-bottom: none;
    border-color: $pink-light;
    box-sizing: border-box; } }

.appendOneToCart {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transition: border 3000ms linear;

  @media screen and (max-width: $mobile) {
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px; } }

.appendToCartLoadingContainer {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: appendToCartLoading;
  width: 100%;
  height: 100%; }

.appendToCartLoading {
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  height: 100%;
  position: relative;

  &:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 100%;
    background-color: $pink; }

  @media screen and (max-width: $mobile) {
    animation-name: none; } }
