@import "~styles/store/variables";

.bannerImg {
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover; }

.mask {
  z-index: 10;
  background-color: $pink;
  opacity: 0.2;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

.nav {
  width: 1210px;
  background: $white;
  border-radius: 3px;
  position: absolute;
  top: 100px;
  z-index: 10000;
  line-height: 20px;

  @media screen and (max-width: $smallDesktop) {
    width: 980px;
    top: 98px; }

  @media screen and ( max-width: $tablet) {
    width: 760px;
    top: 126px;
    left: 0px; }

  @media screen and ( max-width: $mobile) {
    position: relative;
    width: auto;
    top: 48px;
    text-align: left;
    padding: 14px 20px 25px 20px;
    border-radius: 0 3px 3px 3px; }

  &:before {
    content: " ";
    display: block;
    width: 326px;
    height: 100%;
    background: $pink-light;
    border-radius: 3px 0 0 3px;
    position: absolute;
    top: 0px;
    left: 0px;

    @media screen and ( max-width: $smallDesktop) {
      width: 246px; }

    @media screen and ( max-width: $mobile) {
      position: relative; } }

  &:after {
    content: " ";
    display: block;
    width: 3px;
    height: 100%;
    background: $pink-light;
    position: absolute;
    top: 0px;
    left: 612px;

    @media screen and ( max-width: $smallDesktop) {
      left: 492px; }

    @media screen and ( max-width: $mobile) {
      position: relative; } } }

.navL {
  float: left;
  width: 306px;
  padding: 31px 0 31px 20px;
  position: relative;
  z-index: 10002;

  @media screen and (max-width: $smallDesktop) {
    width: 236px;
    padding: 21px 0 21px 10px; }

  @media screen and ( max-width: $mobile) {
    float: none;
    width: auto;
    padding: 0;
    margin: 0 -20px;
    font-size: 12px; }

  li {
    padding: 0 0 5px 0;
    font-weight: bold;

    @media screen and ( max-width: $mobile) {
      padding: 0;
      margin: 0 20px;
      border-bottom: 2px solid $pink-light;
      font-weight: 400;

      &:last-child {
        border: 0; } }

    &.navT1 {
      display: none;
      color: $black;
      font-weight: bold;

      @media screen and ( max-width: $mobile) {
        display: block;
        padding: 8px 0 7px 0;
        border: 0; } }

    a {
      display: block;
      color: $black;

      @media screen and ( max-width: $mobile) {
        padding: 8px 10px 8px 0;
        color: $black; }

      &:hover, &.active {
        color: $pink; }

      &.navPar {
        background: url("~images/nav.svg") no-repeat right 5px center;

        @media screen and ( max-width: $mobile) {
          background: url("~images/nav_m.svg") no-repeat right center !important; }

        &:hover {
          background: url("~images/nav_h.svg") no-repeat right 5px center;

          @media screen and ( max-width: $mobile) {
            background: url("~images/nav_m.svg") no-repeat right center !important; } } }

      &.active {
        background: $white url("~images/nav_h.svg") no-repeat right 5px center !important;

        @media screen and ( max-width: $mobile) {
          padding: 8px 10px 8px 0;
          color: $black;

          &:hover {
            color: $pink;
            background: none !important; } } } } } }

.navR {
  float: right;
  display: none;
  width: 814px;
  padding: 31px 50px 31px 0;

  @media screen and ( max-width: $smallDesktop) {
    width: 700px;
    padding: 21px 20px 21px 0; }

  @media screen and ( max-width: $tablet) {
    width: 480px; }

  @media screen and ( max-width: $mobile) {
    float: none;
    display: none;
    width: auto;
    padding: 0;
    margin: 0 -20px; }

  &.open {
    display: block;

    @media screen and ( max-width: $mobile) {
      display: none; }

    @media screen and ( max-width: $mobile) {
      display: block; } } }

.navLv2 {
  float: left;
  width: 267px;
  padding: 31px 24px 0 5px;

  @media screen and ( max-width: $smallDesktop) {
    width: 232px;
    padding: 21px 24px 0 5px; }

  @media screen and ( max-width: $mobile) {
    float: none;
    width: auto;
    padding: 0; }

  li {
    padding: 0 0 5px 0;

    @media screen and ( max-width: $mobile) {
      margin: 0 20px 0 35px;
      border-bottom: 2px solid $pink-light;

      &:last-child {
        border: 0; } }

    a {
      display: block;
      border-radius: 2px;
      color: $black;

      @media screen and ( max-width: $mobile) {
        padding: 8px 10px 8px 0;
        border-radius: 0px;
        color: $black; }

      &:hover, &.active {
        color: $pink; }

      &.navPar2 {
        padding-right: 9px;
        background: url("~images/nav.svg") no-repeat right 0px center;

        @media screen and ( max-width: $mobile) {
          background: url("~images/nav_m.svg") no-repeat right center !important; }

        &:hover {
          background: url("~images/nav_h.svg") no-repeat right 0px center;

          @media screen and ( max-width: $mobile) {
            background: url("~images/nav_m.svg") no-repeat right center !important; } } }

      &.active {
        background: $pink-light url("~images/nav_h.svg") no-repeat right 0px center !important;

        @media screen and ( max-width: $mobile) {
          padding: 8px 10px 8px 0;
          border-radius: 0px;
          color: $black;
          background: none !important;

          &:hover {
            color: $pink; } } } } } }

.navLv3 {
  float: left;
  display: none;
  width: 210px;
  padding: 4px 0 0 0;

  @media screen and ( max-width: $smallDesktop) {
    width: 175px; }

  @media screen and ( max-width: $mobile) {
    float: none;
    display: none;
    width: auto;
    padding: 0; }

  &.open {
    display: block;

    @media screen and ( max-width: $mobile) {
      display: none; } }

  li {
    padding: 7px 0 7px 0;

    @media screen and ( max-width: $mobile) {
      padding: 0;
      margin: 0 20px 0 35px;
      border-bottom: 2px solid $pink-light;
      &:last-child {
        border: 0; } }

    a {
      color: $black;

      @media screen and ( max-width: $mobile) {
        display: block;
        padding: 8px 0;
        color: $black;

        &.active {
          display: block;
          padding: 8px 0;
          color: $black;

          &:hover {
            color: $pink; } } }

      &:hover, &.active {
        color: $pink; } } } }

.navLv2 li.backCat, .navLv3 li.backCat, .navLv2 li.backCat2, .navLv3 li.backCat2 {
  font-weight: bold; }

.navLv2 li.backCat a, .navLv3 li.backCat a, .navLv2 li.backCat2 a, .navLv3 li.backCat2 a {
  color: $black;
  position: relative; }

.navLv2 li.backCat a:before, .navLv3 li.backCat a:before, .navLv2 li.backCat2 a:before, .navLv3 li.backCat2 a:before {
  content: " ";
  display: block;
  width: 7px;
  height: 10px;
  background: url("~images/back.svg") no-repeat;
  position: absolute;
  top: 14px;
  left: -17px; }

.navLv2 li.backCat a:hover, .navLv3 li.backCat a:hover, .navLv2 li.backCat2 a:hover, .navLv3 li.backCat2 a:hover {
  color: $pink; }

.navLv2 li.navT2, .navLv3 li.navT2 {
  background: $white-2;
  color: $black;
  font-weight: bold;

  @media screen and ( max-width: $mobile) {
    padding: 8px 20px 8px 35px;
    margin: (-2px) 0 0 0;
    border: 0; } }

.navRr {
  float: right;
  width: 250px;
  padding: 21px 20px 21px 0;
  text-align: center;

  @media screen and ( max-width: $smallDesktop) {
    width: 200px; }

  @media screen and ( max-width: $tablet) {
    display: none; } }

.navProd {
  max-width: 250px;
  margin: 0 auto 32px auto;

  a {
    display: block;
    height: 380px;
    border-radius: 3px;
    position: relative;

    &:before {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, $black 0%, rgba(22, 0, 19, 0) 100%);
      opacity: 0.3;
      border-radius: 3px;
      position: absolute;
      top: 0px;
      left: 0px; }

    &:after {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      background: $pink-2;
      opacity: 0;
      border-radius: 3px;
      position: absolute;
      top: 0px;
      left: 0px;
      transition: all 0.3s 0.02s ease; }

    &:hover:after {
      opacity: 0.2; } } }

.navProdT {
  display: block;
  width: 90%;
  position: absolute;
  top: 25px;
  left: 5%;
  color: $white;
  font-size: 20px;
  line-height: 20px;
  font-family: $font-secondary;

  span {
    display: block;
    padding: 16px 0 0 0;
    font-size: 30px;
    line-height: 30px; } }

.navProdP {
  display: block;
  padding: 5px 12px;
  background: $white;
  border-radius: 2px;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  color: $pink;
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  white-space: nowrap; }

.navBrand {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 0 0 21px 0;

  a:hover {
    opacity: 0.5; }

  img {
    height: 42px;
    vertical-align: bottom;

    @media screen and (max-width: $smallDesktop) {
      height: 32px; } } }

.navMob {
  margin: 0 -20px;
  padding: 5px 0 0 0;

  ul {
    padding: 5px 20px;
    border-top: 2px solid $white-2;

    li {
      border-bottom: 2px solid $pink-light;
      font-size: 12px;
      line-height: 19px;

      &:last-child {
        border: 0; }

      a {
        display: block;
        padding: 10px 0;
        color: $black;

        &:hover {
          color: $pink; } }

      &.active a {
        color: $pink; } } } }

.navMobIcons ul li {
  font-size: 13px;
  font-weight: bold;

  a {
    padding: 10px 0 10px 27px; } }

.navMobCart a {
  background: url("~images/top_cart.svg") no-repeat 0px (center / 16px) auto; }

.navMobUser a {
  background: url("~images/top_user.svg") no-repeat 1px (center / 13px) auto; }

.navMobComp a {
  background: url("~images/top_comp.svg") no-repeat 1px (center / 13px) auto; }

.navMobLike a {
  background: url("~images/top_like.svg") no-repeat 0px (center / 15px) auto; }

.navMobTel {
  padding: 18px 0 0 0;
  margin: 5px -20px 0 -20px;
  border-top: 2px solid $white-2;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  text-align: center;

  a {
    opacity: 0.5;
    color: $black;

    &:hover {
      opacity: 1;
      color: $black; } } }

.navMobSoc {
  text-align: center;
  padding: 14px 0 0 0; }

.soc a {
  display: inline-block;
  height: 18px;
  margin: 0 0 0 12px;
  opacity: 0.15; }

.botSoc a, .navMobSoc a {
  height: 24px;
  margin: 0 7px; }

.soc a:hover {
  opacity: 1; }

.socVk {
  width: 23px;
  background: url("~images/soc_vk.svg") no-repeat (center / contain); }

.botSoc .socVk, .navMobSoc .socVk {
  width: 30px; }

.socVk:hover {
  background-image: url("~images/soc_vk_h.svg"); }

.socFb {
  width: 19px;
  background: url("~images/soc_fb.svg") no-repeat (center / contain); }

.botSoc .socFb, .navMobSoc .socFb {
  width: 24px; }

.socFb:hover {
  background-image: url("~images/soc_fb_h.svg"); }

.socIn {
  width: 19px;
  background: url("~images/soc_in.svg") no-repeat (center / contain); }

.botSoc .socIn, .navMobSoc .socIn {
  width: 24px; }

.socIn:hover {
  background-image: url("~images/soc_in_h.svg"); }

.socTg {
  width: 21px;
  background: url("~images/soc_tg.svg") no-repeat (center / contain); }

.botSoc .socTg, .navMobSoc .socTg {
  width: 27px; }

.socTg:hover {
  background-image: url("~images/soc_tg_h.svg"); }

@media screen and ( max-width: $mobile) {

  .openLv3 {
    display: none; }

  li.navT2 {
    background: $white-2;
    color: $black;
    font-weight: bold;
    padding: 8px 20px 8px 35px;
    margin: -2px 0 0 0;
    border: 0; }

  .showAllProducts {
    font-weight: bold; } }
