@import "~styles/store/variables";

.warning {
  color: $red;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  height: 13px;

  @media screen and (max-width: $mobile) {
    font-size: 9px; } }

.warningWithMargin {
  margin-bottom: 7px;

  @media screen and (max-width: $mobile) {
    margin-bottom: 21px;
    font-size: 11px; } }

.warningIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $red;
  color: $white;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  padding: 0;
  margin: 0 0 0 6px;
  font-size: 10px;
  font-weight: 800;
  box-sizing: border-box;

  @media screen and (max-width: $mobile) {
    margin: 0 0 0 4px; } }

.warningIconSign {
  margin-left: .6px; }
