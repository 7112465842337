@import "~styles/store/variables.scss";

.purchasedBot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $mobile) {
    height: 75px; } }

.deleteIcon {
    display: block;
    width: 13px;
    height: 13px;
    background: url("~images/del.svg") no-repeat;
    position: absolute;
    top: 16px;
    right: 19px;
    opacity: 0.3;
    font-size: 0;
    color: transparent;

    &:hover {
        opacity: 1; } }
