@import "~styles/store/variables.scss";

.userMenuOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9000;
  background: #3F0738;
  opacity: 0.2; }

.userMenu {
  width: 240px;
  padding: 8px 0;
  background: $white;
  border-radius: 3px;
  position: absolute;
  top: 52px;
  right: 50%;
  z-index: $overflow-all-z-index;
  transform: translateX(50%);
  text-align: left;

  &.isOpen {
    display: block; }

  @media screen and (max-width: $smallDesktop) {
    right: -50px;
    transform: none; }

  @media screen and (max-width: $mobile) {
    top: 48px;
    right: -78px;
    width: 280px; }

  & li {
    border-bottom: 2px solid $pink-light;
    font-weight: bold; }

  & li:last-child {
	  border: 0; }

  & li a {
    display: block;
    padding: 5px 22px;
    color: $black;

    @media screen and (max-width: $mobile) {
      padding: 9px 20px; } }

  & li.topUser0 a {
    color: $green-light; }

  & li.topUser1 a {
    color: $blue; }

  & li.topUser2 a {
    color: $blue-dark; }

  & li.topUser6 a {
    cursor: pointer;
    color: $black;
    opacity: .5; }

  & li a:hover {
    background: $pink-light;
    color: $pink;
    opacity: 1; } }

.order {
  padding: 0 10px 5px 10px; }

.orderContent {
  background-color: $pink-light;
  padding: 11px 14px 17px 14px;
  border-radius: 3px; }
