@import "~styles/store/variables";

.userFormInput {
  float: left;
  width: 283px;
  padding: 0 20px 14px 0;

  @media screen and (max-width: $smallDesktop) {
    width: 226px;
    padding: 0 10px 20px 0; }

  @media screen and (max-width: $tablet) {
    width: 220px; }

  @media screen and (max-width: $mobile) {
    float: none;
    width: auto;
    padding: 0 0 1px 0;
    margin-bottom: 14px; }

  &:nth-child(3n) {
    padding: 0 0 1px 0;

    @media screen and (max-width: $smallDesktop) {
      padding: 0 0 20px 0; }

    @media screen and (max-width: $mobile) {
      padding: 0 0 1px 0; } }

  &:nth-last-child {
    padding: 0 0 1px 0;

    @media screen and (max-width: $smallDesktop) {
      padding: 0 0 20px 0; }

    @media screen and (max-width: $mobile) {
      float: none;
      width: auto;
      padding: 0 0 12px 0; } } }

.label {
  display: block;
  padding: 0 0 7px 0;
  color: $black;
  line-height: 23px;
  font-weight: bold;

  @media screen and (max-width: $mobile) {
    padding: 0 0 1px 0; } }

.input {
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  padding: 7px 16px 9px 16px;
  background: $white;
  background: $pink-light;
  border: 3px solid $white-2;
  transition: all 0.3s 0.02s ease;
  border-radius: 3px;
  -webkit-appearance: none;
  line-height: 23px;

  &.heightAuto {
    height: auto; }

  &:hover, &:focus {
    background-color: $white-3;
    border-color: $pink-light-3; }

  &::placeholder {
    color: $pink-3;
    transition: all 0.3s 0.02s ease; } }

.clear {
  clear: both; }

.separateField {
  margin-top: 20px;
  float: none; }

.phoneNumber {
  display: flex;
  align-items: center;

  @media screen and (max-width: $mobile) {
    display: block; } }

.phoneNumberEdit {
  margin: 0 0 0 20px;

  @media screen and (max-width: $mobile) {
    margin: 10px 0 0 0; } }

.noEmailContainer {
  margin-left: 14px;
  font-weight: normal; }

.fieldHint {
  font-size: 11px;
  line-height: 14px;
  opacity: .7;

  @media screen and (min-width: $smallDesktop) {
    margin-top: 3px; } }
