
.search_podbor {
  display: none;
  width: 100%;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: 67px;
  left: 0;
  z-index: $overflow-all-z-index;
  line-height: 21px;

  @media screen and ( max-width: $mobile) {
    top: 48px; } }

.top_search.open .search_podbor {
  display: block; }

.search_podbor_df {
  display: flex;
  align-content: flex-start;
  align-items: stretch; }

.search_podbor_l {
  box-sizing: border-box;
  width: 969px;
  padding: 17px 5px 30px 30px;

  @media screen and ( max-width: $smallDesktop) {
    width: 759px;
    padding: 17px 5px 20px 20px; }

  @media screen and ( max-width: $tablet) {
    width: 610px; }

  @media screen and ( max-width: $mobile) {
    width: auto;
    padding: 12px 0 10px 4px;
    margin: 0 2px 10px 0 !important; } }

.search_podbor_r {
  box-sizing: border-box;
  width: 241px;
  padding: 17px 30px;
  background: #FFF8FE;
  border-radius: 3px;
  text-align: center;

  @media screen and ( max-width: $smallDesktop) {
    width: 200px;
    padding: 17px 20px; }

  @media screen and ( max-width: $tablet) {
    width: 150px;
    padding: 17px 10px; }

  @media screen and ( max-width: $mobile) {
    display: none; } }

.search_podbor_t {
  padding: 0 0 21px 0;
  color: #2D0027;
  font-size: 22px;
  line-height: 33px;
  font-weight: bold;

  @media screen and ( max-width: $tablet) {
    padding: 0 0 16px 0; }

  @media screen and ( max-width: $mobile) {
    padding: 0 0 12px 0;
    font-size: 16px;
    line-height: 22px; } }



.search_brend {
  padding: 7px 0 31px 0;

  img {
    vertical-align: bottom; }

  a:hover {
    opacity: 0.5; } }

.search_podbor_all {
  padding: 5px 25px 0 0;

  @media screen and ( max-width: $smallDesktop) {
    padding: 5px 20px 0 0; }

  @media screen and ( max-width: $tablet) {
    padding: 5px 15px 0 0; }

  a {
    display: block;
    height: 45px;
    background: #FFF8FE;
    border-radius: 3px;
    color: #ab95a8;
    line-height: 45px;
    font-weight: bold;
    text-align: center;

    &:hover {
      background: #F5DEF3;
      color: #ba51ac; }

    span {
      padding: 0 16px 0 0;
      background: url('~images/next2.svg') no-repeat right 7px;
      transition: background 0.3s 0.02s ease; }

    &:hover span {
      background-image: url('~images/next2_h.svg'); } } }

@media screen and ( max-width: $smallDesktop) {
  .search_podbor_l .prod_list_sm:nth-child(12), .search_podbor_l .prod_list_sm:nth-child(13) {
    display: none; } }

@media screen and ( max-width: $tablet) {
  .search_podbor_l .prod_list_sm:nth-child(10), .search_podbor_l .prod_list_sm:nth-child(11) {
    display: none; } }
