.seo_text {
  float: right;
  width: 899px;

  @media screen and (max-width: $smallDesktop) {
    width: 732px; }

  @media screen and (max-width: $tablet) {
    float: none;
    width: auto; } }
