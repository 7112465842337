@import "~styles/store/variables.scss";

.repeatAppendToCartContainer {
  display: block;
  margin-top: 10px;

  @media screen and (max-width: $mobile) {
    margin: 0; } }

.repeatAppendToCart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39px;
  width: 157px;
  border: 3px solid $white-shadow-2;
  border-radius: 2px;
  font-size: 13px;
  font-weight: bold;
  text-transform: none;

  @media screen and (max-width: $mobile) {
    width: 100%;
    font-weight: bold;
    border: none;
    border-radius: 0;
    height: unset; }

  &:hover {
    border-color: $white-shadow-3; } }
