@import "~styles/store/variables";

.notificationIcon {
  position: absolute;
  background: $red;
  width: 5px;
  height: 5px;
  top: 8px;
  right: 19px;
  border-radius: 50%;

  @media screen and (max-width: $mobile) {
    top: 5px;
    right: calc(50% - 13px); } }
