HTML, BODY, DIV, SPAN, APPLET, OBJECT, IFRAME, H1, H2, H3, H4, H5, H6, P, BLOCKQUOTE, PRE, A, ABBR, ACRONYM, ADDRESS, BIG, CITE, CODE, DEL, DFN, EM, FONT, IMG, INS, KBD, Q, S, SAMP, SMALL, STRIKE, STRONG, TT, VAR, B, U, I, CENTER, DL, DT, DD, OL, UL, LI, FIELDSET, FORM, LABEL, LEGEND {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent; }

SUP, SUB {
    line-height: 1px; }

BODY {
    line-height: 1; }

UL {
    list-style: none; }

 :focus {
    outline: 0; }

INS {
    text-decoration: none; }

DEL {
    text-decoration: line-through; }

input::-moz-focus-inner {
    border: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary, .block {
    display: block; }

audio, canvas, video {
    display: inline-block; }

audio:not([controls]) {
    display: none;
    height: 0; }

[hidden], .none, .hidden, .on980, .on760, .on320, .only980, .only760 {
    display: none; }

.clear, .clr {
    clear: both; }

hr {
    &.clear, &.clr {
        padding: 0;
        margin: 0;
        height: 1px;
        overflow: hidden;
        border: 0; } }

.clearfix {
    &:before, &:after {
        content: " ";
        display: table; } }

.clf {
    &:before, &:after {
        content: " ";
        display: table; } }

.clearfix:after, .clf:after {
    clear: both; }

.clearfix, .clf {
    *zoom: 1; }

.alignright, .right {
    float: right; }

.alignleft, .left {
    float: left; }

.center {
    text-align: center; }

.centered, .aligncenter {
    display: block;
    margin: 0 auto; }

img {
    &.alignright, &.right {
        margin: 0 0 7px 20px;
        display: inline; }

    &.alignleft, &.left {
        margin: 0 20px 7px 0;
        display: inline; } }

a img {
    border: none; }

img {
    max-width: 100%; }

.entry img {
    height: auto; }
