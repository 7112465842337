@import "~styles/variables";
@import "~styles/store/variables";
@import "~styles/store/_helpers";

.cart {
  position: relative; }

.cart_l {
    float: left;
    width: 890px;
    color: #2D0027; }

.cart_mag {
    margin: 0 0 35px 0;
    padding: 27px 38px 36px 38px;
    border: 2px solid #F5DEF3;
    border-radius: 3px; }

@media (min-width: 761px) {
    .cart_mag {
        display: block !important; } }

.cart_mag_t {
    padding: 0 0 21px 0;
    font-size: 22px;
    line-height: 33px;
    font-weight: bold;

    span {
        opacity: 0.25;
        font-weight: 400; } }

.cart_prod {
    padding: 0 0 8px 0; }

.cart_prod_img {
    float: left;
    width: 67px;
    padding: 12px 0 0 0;

    img {
        vertical-align: bottom; } }

.cart_prod_t {
    float: left;
    width: 390px;
    padding: 9px 0 0 40px;
    line-height: 20px;
    font-weight: bold;

    a {
        color: #2D0027;

        &:hover {
            color: $pink; } } }

.cart_count {
    float: left;
    margin: 11px 0 0 29px; }

.count {
    width: 79px;
    height: 41px;
    background: $pink-light;
    border: 3px solid $white-2;
    border-radius: 3px;
    position: relative; }

.count_m, .count_p {
    display: block;
    width: 12px;
    height: 41px;
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    top: 0px;
    cursor: pointer;
    transition: all 0.3s 0.02s ease; }

.count_m {
    background: url("~images/count_m.svg") no-repeat center;
    left: 8px; }

.count_p {
    background: url("~images/count_p.svg") no-repeat center;
    right: 8px; }

.count_m:hover, .count_p:hover {
    opacity: 0.5; }

.count_i {
    display: block;
    width: 24px;
    height: 31px;
    padding: 4px 0 6px 0;
    margin: 0 auto;
    border: 0;
    background: none;
    line-height: 21px;
    text-align: center; }

.cart_prod_p {
    float: left;
    width: 90px;
    padding: 9px 0 0 95px;
    color: $pink;
    font-size: 15px;
    line-height: 20px;
    font-weight: bold;

    .old {
        display: block;
        padding: 1px 0 0 0;
        text-decoration: line-through;
        opacity: 0.5;
        font-size: 13px;
        line-height: 20px;
        font-weight: bold; } }

.cart_prod_del {
    float: right;

    a {
        display: block;
        width: 13px;
        height: 13px;
        background: url("~images/del.svg") no-repeat;
        opacity: 0.3;

        &:hover {
            opacity: 0.7; } } }

.cart_dost {
    padding: 22px 39px 0 39px;
    margin: 23px -38px 0 -38px;
    border-top: 2px solid #fbf2fa;
    line-height: 20px; }

.cart_dost_lab {
    float: left;
    width: 100px;
    padding: 0 6px 26px 0; }

.cart_dost_types {
    float: left;
    width: 580px;
    padding: 0 19px 26px 0; }

.cart_dost_type {
    float: left;
    margin: (-2px) 28px 0 0;
    color: #968093;
    font-weight: bold;

    label {
        cursor: pointer;

        &:hover {
            color: #cf89c7; } }

    span {
        transition: all 0.3s 0.02s ease; }

    .checked ~ span {
        color: #2D0027; } }

.cart_dost_prices {
    float: left;
    width: 90px;
    padding: 0 0 26px 0;
    color: $pink;
    font-weight: bold; }

.cart_dost_ps, .cart_dost_pp, .cart_dost_s, .cart_dost_p {
    display: none; }

.cart_dost_tk {
    float: left;
    width: 700px;
    padding: 0 0 10px 0; }

.cart_dost_tks > span {
    float: left;
    box-sizing: border-box;
    width: 82px;
    height: 79px;
    margin: 0 23px 14px 0;
    padding: 34px 5px 5px 5px;
    border: 3px solid #F7ECF5;
    border-radius: 3px;
    transition: all 0.3s 0.02s ease;
    cursor: pointer;
    color: #91798d;
    font-size: 13px;
    line-height: 17px;
    text-align: center; }

.cart_dost_tk_pek {
    background: url("~images/cart_dost_tk_pek.png") no-repeat center 10px / 54px auto; }

.cart_dost_tk_cdek {
    background: url("~images/cart_dost_tk_cdek.png") no-repeat center 10px / 62px auto; }

.cart_dost_tk_dl {
    background: url("~images/cart_dost_tk_dl.png") no-repeat center 6px / 64px auto; }

.cart_dost_tk_pr {
    background: url("~images/cart_dost_tk_pr.png") no-repeat center 9px / 65px auto; }

.cart_dost_tk_ems {
    background: url("~images/cart_dost_tk_ems.png") no-repeat center 10px / 46px auto; }

.cart_dost_tks > span {
    &:hover {
        border-color: #E8BCE2;
        color: #2D0027; }

    &.active {
        border-color: #E8BCE2;
        color: #2D0027;
        font-weight: bold; } }

.cart_dost_adr {
    float: left;
    width: 660px; }

.cart_dost_adr_sel {
    width: 503px; }

.cart_dost_adr_add {
    padding: 16px 0 0 0; }

.cart_dost_sa {
    float: left;
    width: 660px;
    padding: 10px 0 0 0;
    font-weight: bold;

    span {
        padding: 0 0 0 6px;
        opacity: 0.5;
        font-weight: 400; } }

.cart_dost_adr_inf {
    padding: 13px 0 0 0;
    clear: both;
    opacity: 0.5; }

.cart_oform_mob {
    display: none;
    box-sizing: border-box;
    width: 100%;
    padding: 0 20px;
    background: $pink-light;
    box-shadow: 0px 0px 20px rgba(57, 0, 50, 0.2);
    position: fixed;
    left: 0px;
    bottom: 0px;
    z-index: 300;

    .btn {
        width: 100%; } }

.cart_oform_mob2 {
    display: none;
    padding: 0 0 10px 0;

    &.show_everywhere {
      display: block; }

    .btn {
        width: 100%; } }

.cart_form {
    padding: 10px 0 0 0; }

@media (min-width: 761px) {
    .cart_form {
        display: block !important; } }

.cart_form_inp {
    float: left;
    width: 283px;
    padding: 0 20px 21px 0;

    &:nth-child(3n) {
        padding: 0 0 21px 0; } }

.cart_itogs {
    float: right;
    width: 284px; }

@media (min-width: 761px) {
    .cart_itogs {
        display: block !important; } }

.cart_itogs_bg {
    padding: 26px 27px 36px 27px;
    background: $pink-light;
    border-radius: 3px;
    color: #2D0027;
    font-weight: bold; }

.cart_itogs_t {
    float: left;
    width: 100px;
    padding: 0 0 24px 0;
    font-size: 25px;
    line-height: 34px;
    font-family: Archive;
    text-transform: uppercase; }

.cart_itogs_s {
    float: left;
    width: 119px;
    padding: 0 0 24px 11px;
    color: $pink;
    font-size: 25px;
    line-height: 34px;
    text-transform: uppercase; }

.cart_itog_l {
    float: left;
    width: 100px;
    padding: 0 0 12px 0;
    line-height: 22px;
    opacity: 0.5; }

.cart_itog_r {
    float: left;
    width: 119px;
    padding: 0 0 12px 11px;
    line-height: 22px; }

.cart_olat {
    padding: 16px 0 0 0;

    label > {
        div {
            float: left; }

        span {
            display: block;
            margin: 0 0 0 35px;
            color: #967c92;
            line-height: 25px;
            cursor: pointer;

            span {
                display: block;
                font-size: 13px;
                line-height: 18px;
                font-weight: 400;
                opacity: 0.7; } } }

    .checked ~ span {
        color: #2D0027; } }

.cart_go {
    padding: 33px 0 0 0;

    .btn {
        width: 100%; } }

@media screen and (max-width: $smallDesktop) {
    .cart_l {
        width: 700px; }

    .cart_itogs {
        width: 260px; }

    .cart_itogs_bg {
        padding: 20px 15px; }

    .cart_mag {
        margin: 0 0 30px 0;
        padding: 20px; }

    .cart_prod_t {
        width: 330px;
        padding: 9px 0 0 20px; }

    .cart_count {
        margin: 11px 0 0 20px; }

    .cart_prod_p {
        padding: 9px 0 0 25px; }

    .cart_dost {
        padding: 20px 20px 0 20px;
        margin: 20px -20px 0 -20px; }

    .cart_dost_types {
        width: 422px; }

    .cart_dost_type {
        margin: (-2px) 20px 0 0; }

    .cart_dost_tk, .cart_dost_adr {
        width: 540px; }

    .cart_dost_adr_sel {
        width: 363px; }

    .cart_dost_sa {
        width: 540px; }

    .cart_form {
        padding: 0; }

    .cart_form_inp {
        width: 226px;
        padding: 0 10px 20px 0;

        &:nth-child(3n) {
            padding: 0 0 20px 0; } } }

@media screen and (max-width: $tablet) {
    .cart_l {
        float: none;
        width: auto; }

    .cart_prod_t {
        width: 370px; }

    .cart_form_inp {
        width: 240px; }

    .cart_itogs {
        float: none;
        width: auto;
        height: auto !important;

        .inner-wrapper-sticky {
            position: relative !important;
            top: auto !important;
            left: auto !important;
            bottom: auto !important;
            width: auto !important;
            transform: none !important; } }

    .cart_itogs_bg {
        padding: 30px; }

    .cart_itogs_s, .cart_itog_r {
        width: 219px; } }

@media screen and (max-width: $mobile) {
    .cart_mag {
        margin: 0 0 25px 0;
        padding: 10px 16px 20px 16px; }

    .cart_mag_t {
        font-size: 15px;
        line-height: 23px;

        span {
            display: block;
            font-size: 13px;
            line-height: 20px; } }

    .cart_prod {
        padding: 0 0 26px 0;
        position: relative; }

    .cart_prod_img {
        width: 52px;
        padding: 0; }

    .cart_prod_t {
        float: none;
        width: auto;
        padding: 0;
        margin: 0 15px 0 67px;
        font-size: 13px;
        line-height: 18px; }

    .cart_count {
        float: left;
        margin: 11px 0 0 15px; }

    .cart_prod_p {
        float: right;
        width: 80px;
        padding: 14px 0 0 0; }

    .cart_prod_del {
        float: none;
        position: absolute;
        top: 0px;
        right: 0px; }

    .cart_dost {
        padding: 13px 16px 0 16px;
        margin: 0 -16px 0 -16px; }

    .cart_dost_lab {
        float: none;
        width: auto;
        padding: 0 0 5px 0;
        font-size: 13px; }

    .cart_dost_types {
        float: none;
        width: auto;
        padding: 0 0 8px 0; }

    .cart_dost_type {
        margin: 5px 20px 5px 0; }

    .cart_dost_prices {
        display: none; }

    .cart_dost_tk {
        float: none;
        width: auto;
        margin: 0 -16px 0 0;
        position: relative;

        &:before {
            content: " ";
            display: block;
            width: 30px;
            height: 79px;
            background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            position: absolute;
            top: 0px;
            right: 0px;
            z-index: 50; } }

    .cart_dost_tks {
        max-width: 100%;
        padding: 0 0 15px 0;
        white-space: nowrap;
        position: relative;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            width: 0;
            height: 0px; }

        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;

        > span {
            float: none;
            display: inline-block;
            margin: 0 9px 0 0; } }

    .cart_dost_adr {
        float: none;
        width: auto; }

    .cart_dost_adr_sel {
        width: auto;

        select {
            max-width: 90%; }

        .jq-selectbox__select {
            height: auto;
            padding: 9px 42px 9px 18px;
            line-height: 21px; }

        .jq-selectbox__select-text, .jq-selectbox li, .jq-select-multiple li {
            white-space: normal; } }

    .cart_dost_adr_add {
        padding: 10px 0 0 0;

        .btn {
            width: 100%; } }

    .cart_dost_sa {
        float: none;
        width: auto;
        padding: 0; }

    .cart_oform_mob, .cart_oform_mob2 {
        display: block; }

    .cart_form {
        display: none;
        padding: 0 0 18px 0; }

    .cart_form_inp {
        float: none;
        width: auto;
        padding: 0 0 12px 0;

        &:nth-child(3n) {
            float: none;
            width: auto;
            padding: 0 0 12px 0; } }

    .cart_itogs {
        display: none; }

    .cart_itogs_bg {
        padding: 17px 24px 20px 24px; }

    .cart_itogs_t {
        padding: 0 0 14px 0;
        font-size: 18px;
        line-height: 27px; }

    .cart_itogs_s {
        width: 119px;
        padding: 0 0 14px 11px;
        font-size: 18px;
        line-height: 27px; }

    .cart_itog_l {
        padding: 0 0 7px 0;
        font-size: 13px; }

    .cart_itog_r {
        width: 119px;
        padding: 0 0 7px 11px;
        font-size: 13px; }

    .cart_olat {
        padding: 16px 0 0 0;
        font-size: 13px;

        label > {
            div {
                float: left; }

            span {
                padding: 2px 0 0 0;
                line-height: 20px;

                span {
                    font-size: 12px;
                    line-height: 16px; } } } }

    .cart_go {
        padding: 20px 0 0 0; } }
