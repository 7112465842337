.loader-container {
    text-align: center;
    .loader-text {
        font-family: $font-secondary;
        font-style: normal;
        font-weight: normal;
        font-size: 35px;
        line-height: 65px;
        color: $pink;

        @media screen and (max-width: $mobile) {
            font-size: 15px;
            line-height: 35px; } }

    .loader-img {
        img {
            width: 300px;

            @media screen and (max-width: $mobile) {
                width: 150px; } } } }

.img-loader {
    display: block;
    margin-right: auto;
    margin-left: auto; }
