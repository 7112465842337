.blog-page {
  .blog {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    .container {
      box-sizing: border-box;
      margin: 0;
      min-width: 0;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;

      .articles {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: -0.5rem;

        .card {
          min-width: 18rem;
          max-width: 580px;
          -webkit-box-flex: 1;
          -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          padding: 0.5rem;
          -webkit-box-flex: 1;
          -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          padding: 0.5rem;

          @media screen and (max-width: $tablet) {
            max-width: none;
            min-width: auto; }

          .article-container {
            box-sizing: border-box;
            margin: 0;
            min-width: 0;
            background-color: #fff;
            border-radius: 1rem;
            -webkit-transition: -webkit-transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
            -webkit-transition: transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
            transition: transform 250ms ease, box-shadow 250ms ease, color 250ms ease;
            box-shadow: 1px 1px 5px 0;
            overflow: hidden;
            height: 100%;

            .article {
              -webkit-flex-direction: row;
              -ms-flex-direction: row;
              flex-direction: row;
              box-sizing: border-box;
              margin: 0;
              min-width: 0;
              -webkit-align-items: stretch;
              -webkit-box-align: stretch;
              -ms-flex-align: stretch;
              align-items: stretch;
              height: 100%;
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              border-left-color: $pink;
              border-left-style: solid;
              border-left-width: 5;

              @media screen and (max-width: $mobile) {
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row; }


              .image-container {
                max-width: 35%;
                display: block;
                overflow: hidden;

                img {
                  object-fit: cover;
                  height: 100%;

                  @media screen and (max-width: $mobile) {
                    display: none; } } }

              .container {
                display: flex;
                box-sizing: border-box;
                margin: 0;
                min-width: 0;
                padding: 2rem;
                flex-direction: row;
                @media screen and (max-width: $tablet) {
                  padding: 1rem; }



                .text-container {
                  order: 2;
                  width: 70%;
                  display: -webkit-box;
                  display: -webkit-flex;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-flex-direction: column;
                  -ms-flex-direction: column;
                  flex-direction: column;
                  -webkit-box-pack: center;
                  -webkit-justify-content: center;
                  -ms-flex-pack: center;
                  justify-content: center;
                  -webkit-flex: 1;
                  -ms-flex: 1;
                  flex: 1;
                  .title {
                    -webkit-flex: auto;
                    -ms-flex: auto;
                    flex: auto;
                    box-sizing: border-box;
                    margin: 0;
                    min-width: 0;
                    font-family: 'Inter',sans-serif;
                    font-weight: bold;
                    line-height: 1.33;
                    display: block;
                    color: #2d3748;
                    font-weight: bold;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                    margin-bottom: 1rem;
                    display: block;
                    color: #2d3748;
                    font-weight: bold;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                    margin-bottom: 1rem;
                    font-size: 1.25rem;
                    @media screen and (max-width: $tablet) {
                      -webkit-flex: 0;
                      -ms-flex: 0;
                      flex: 0; } }


                  .content {
                    box-sizing: border-box;
                    margin: 0;
                    min-width: 0;
                    font-size: 0.875rem;
                    margin: 0;
                    -webkit-flex: auto;
                    -ms-flex: auto;
                    flex: auto;
                    margin-bottom: 1rem;
                    display: block;
                    color: $black;
                    overflow-wrap: break-word; } } } } } } } } }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 42px;

    & .page-number {
      font-size: 16px;
      color: $black;

      &:not(:first-child) {
        margin-left: 12px;

        @media screen and (max-width: $mobile) {
          margin-left: 7px; } }
      & a {
        border: 1px solid;
        padding: 5px 8px;
        border-radius: 5px; } } } }

.flatpage img {
  object-fit: contain; }
