@import "~styles/store/variables.scss";
@import "~styles/store/_helpers";

.payBefore {
  color: $black;
  font-size: 12px;
  text-transform: none;

  @media screen and (max-width: $mobile) {
    margin-bottom: 2px; } }

.payBeforeText {
  margin-left: 2px; }

.timer {
  color: $red; }
