@import "~styles/store/helpers";
@import "~styles/store/variables";

.signIn {
  background: $white;
  border-radius: 3px;
  box-sizing: border-box;
  width: 100%;
  max-width: 415px;
  transition: all 200ms;
  text-align: left;

  @media screen and (max-width: $mobile) {
    max-width: 292px;
    top: 25px; } }

@mixin container {
  width: 100%;
  box-sizing: border-box; }

.header {
  @include container;
  padding: 16px;
  margin: 0 0 16px;
  border-radius: 3px 3px 0 0;

  @media screen and (max-width: $mobile) {
    margin: 0;
    padding: 11px 11px 9px; } }

.content {
  @include container;
  padding: 0 60px 54px;

  @media screen and (max-width: $mobile) {
   padding: 0 30px 32px; } }

.middleContainer {
  @include container;
  margin: 0 0 41px;

  @media screen and (max-width: $mobile) {
    margin: 0 0 23px; } }

.bottomContainer {
  @include container; }

.panel {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.title {
  font: 400 30px/23px $font-secondary;
  color: $black;
  margin: 0 0 9px;
  transition: font-size 200ms;

  @media screen and (max-width: $mobile) {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 4px; } }

.titleWithOutSubTitle {
  margin: 0 0 39px;

  @media screen and (max-width: $mobile) {
    margin: 0 0 20px; } }

.subTitle {
  @include default-text;
  color: rgba($black, 0.5);
  margin: 0 0 33px;

  @media screen and (max-width: $mobile) {
    margin: 0 0 18px; } }

.label {
  @include default-text;
  font-weight: bold;
  color: $black;

  @media screen and (max-width: $mobile) {
    font-size: 13px;
    font-weight: bold; } }

.phoneInput {
  @include default-text-input;
  margin: 13px 0 6px;

  @media screen and (max-width: $mobile) {
    margin: 6px 0 0; } }

.error {
  color: $red; }

@mixin description {
  @include default-text;
  color: rgba($black, 0.5); }

.description {
  @include description; }

.smallDescription {
  @include description;
  font-size: 13px; }

.button {
  @include btn-default;
  margin: 11px 0 0;

  @media screen and (max-width: $mobile) {
    margin: 19px 0 0; } }

.link {
  color: rgba($pink, 0.5);
  text-decoration: none; }

.dottedLink {
  font-size: 13px;
  position: relative;
  padding: 0 0 4px;

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background-image: url("data:image/svg+xml,%3Csvg width='114' height='2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.3' d='M.804 2V.632h.984V2H.804zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.983V2h-.984zm2.59 0V.632h.983V2h-.984zm2.59 0V.632h.983V2h-.984zm2.589 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.983V2h-.984zm2.59 0V.632h.983V2h-.984zm2.59 0V.632h.983V2h-.984zm2.589 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.983V2h-.983zm2.59 0V.632h.983V2h-.984zm2.59 0V.632h.983V2h-.984zm2.589 0V.632h.984V2h-.984zm2.59 0V.632h.984V2H52.6zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.983V2h-.984zm2.59 0V.632h.983V2h-.984zm2.59 0V.632h.983V2h-.984zm2.589 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.985zm2.59 0V.632h.983V2H78.5zm2.59 0V.632h.983V2h-.984zm2.589 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.985zm2.59 0V.632h.983V2h-.984zm2.59 0V.632h.983V2h-.984zm2.59 0V.632h.983V2h-.984zm2.589 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984zm2.589 0V.632h.984V2h-.984zm2.59 0V.632h.984V2h-.984z' fill='%23A0148E'/%3E%3C/svg%3E");
    background-position: left center;
    background-repeat: repeat-x;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; } }

.returnLink {
  display: flex;
  align-items: center; }

.returnIconWrapper {
  width: 10px;
  height: 16px;
  margin: 0 16px 0 0;

  @media screen and (max-width: $mobile) {
    width: 6px;
    height: 9px;
    margin: 0 9px 0 0; } }

.returnIcon {
  stroke: $pink;
  opacity: .6;
  width: 10px;
  height: 16px;

  @media screen and (max-width: $mobile) {
    width: 6px;
    height: 9px; } }

.close {
  margin: 0 0 0 auto; }

.closeIconWrapper {
  width: 13px;
  height: 13px;

  @media screen and (max-width: $mobile) {
    width: 11px;
    height: 11px; } }

.closeIcon {
  stroke: $pink;
  opacity: .2;
  width: 13px;
  height: 13px;

  @media screen and (max-width: $mobile) {
    width: 11px;
    height: 11px; } }

.phoneNumber {
  white-space: nowrap; }

.verificationInput {

  .inputContainer {
    height: 40px;
    width: auto;
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;

    @media screen and (max-width: $mobile) {
      margin-top: 5px;
      margin-bottom: 5px; } }

  .inputBlock {
    border-radius: 5px;
    background: $pink-light;
    border: 3px solid $white-2;
    color: black;
    font-size: 24px;
    line-height: 32px;
    margin-left: 30px;
    cursor: default;
    user-select: none;
    box-sizing: border-box;
    height: 100%;
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;

    @media screen and (max-width: $mobile) {
      margin-left: 12px; }

    &:first-child {
      margin-left: 0; }

    &.active {
      border-color: $pink; }

    &.inactive {
      color: $gray-light;
      background-color: $pink-light-2; } } }
