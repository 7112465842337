html {
  height: 100%; }

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #968093;
  font-size: 15px;
  line-height: 28px;
  font-family: $font-primary;
  background: #fff;
  text-align: center; }

.flatpage {
  color: $black;
  overflow-wrap: break-word; }

.wrap {
  width: 1210px;
  margin: 0 auto;
  text-align: left; }

.wrap_main {
  flex: 1 0 auto; }

.cur {
  font-family: $font-cur; }

button {
  cursor: pointer; }

input, textarea {
  color: $black;
  font-size: 15px;
  font-family: $font-primary; }

a {
  color: $pink;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s 0.02s ease;

  &:hover {
    color: #D866C9;
    text-decoration: none; } }

p, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .title {
  margin: 0 0 28px 0; }

.entry {
  table, form, ul, ol, hr {
    margin: 0 0 28px 0; } }

blockquote {
  margin: 0 0 28px 0; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .title {
  color: $black;
  font-weight: 400;
  font-family: $font-secondary;
  text-transform: uppercase; }

h1, .h1 {
  font-size: 50px;
  line-height: 65px; }

h2, .h2 {
  font-size: 40px;
  line-height: 48px; }

h3, .h3 {
  font-size: 30px;
  line-height: 36px; }

h4, .h4 {
  font-size: 24px;
  line-height: 30px; }

h5, .h5 {
  font-size: 20px;
  line-height: 28px; }

h6, .h6 {
  font-size: 18px;
  line-height: 28px; }

.title {
  font-size: 30px;
  line-height: 36px; }

.black {
  color: #000; }

.white {
  color: #fff; }

.grey {
  color: #808080; }

.green {
  color: #34c924; }

.red {
  color: #ff0000; }

.orange {
  color: #ffa500; }

.yellow {
  color: #ffff00; }

.blue {
  color: #0000ff; }

.purple {
  color: #8b00ff; }

.pink {
  color: #ffc0cb; }

.table, .entry table {
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 1px solid #959596;
  border-left: 1px solid #959596; }

.table tr th, .entry table tr th {
  border-bottom: 1px solid #959596;
  border-right: 1px solid #959596;
  background: #f4f4f4;
  padding: 8px 10px; }

.table tr td {
  border-bottom: 1px solid #959596;
  border-right: 1px solid #959596;
  padding: 8px 10px; }

.entry {
  table {
    tr td {
      border-bottom: 1px solid #959596;
      border-right: 1px solid #959596;
      padding: 8px 10px; }

    &.nobord {
      border: 0;
      padding: 0;
      background: none;

      tr {
        th, td {
          border: 0;
          padding: 0;
          background: none; } } } }

  ul {
    padding: 0 0 0 20px;
    list-style: none; } }

ul.list {
  padding: 0 0 0 20px;
  list-style: none; }

.entry ol, ol.list {
  padding: 0 0 0 25px;
  list-style: none; }

.entry {
  ul ul {
    margin: 5px 0 0 0; }

  ol {
    ol, ul {
      margin: 5px 0 0 0; } }

  ul ol {
    margin: 5px 0 0 0; } }

ul.list ul {
  margin: 5px 0 0 0; }

ol.list {
  ol, ul {
    margin: 5px 0 0 0; } }

ul.list ol {
  margin: 5px 0 0 0; }

.entry ol > li, ol.list > li {
  margin: 7px 0 7px 10px;
  list-style: decimal outside; }

.entry ul > li, ul.list > li {
  margin: 7px 0 7px 10px;
  list-style-type: disc; }

code {
  font: 1.1em "Courier New", Courier, Fixed; }

acronym, abbr, span.caps {
  font-size: 0.9em;
  letter-spacing: .07em;
  cursor: help; }

acronym, abbr {
  border-bottom: 1px dashed #999; }

blockquote {
  padding-left: 20px;
  border-left: 5px solid #ddd;

  cite {
    margin: 5px 0 0;
    display: block; } }

.grid {
  clear: both;
  *zoom: 1;

  &:after {
    content: "";
    display: table;
    clear: both; } }

[class*="col-"] {
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 20px 0 0; }

.col-1-8 {
  width: 12.5%; }

.col-1-4 {
  width: 25%; }

.col-1-3 {
  width: 33.33%; }

.col-1-2 {
  width: 50%; }

.col-2-3 {
  width: 66.66%; }

.flatpage_payment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px; }
