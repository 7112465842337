@import "~styles/store/helpers";
@import "~styles/store/variables";

@mixin container {
  width: 100%;
  box-sizing: border-box; }

.header {
  @include container;
  padding: 16px;
  margin: 0 0 16px;
  border-radius: 3px 3px 0 0;

  @media screen and (max-width: $mobile) {
    margin: 0;
    padding: 11px 11px 9px; } }

.actionsHeader {
  display: flex;
  align-items: center;
  height: 45px;
  background-color: $pink-light;
  padding-left: 32px;
  margin-bottom: 57px;
  line-height: 1;

  @media screen and (max-width: $mobile) {
    height: 40px;
    padding-left: 16px;
    margin-bottom: 26px; } }

.link {
  color: rgba($pink, 0.5);
  text-decoration: none; }

.returnLink {
  display: flex;
  align-items: center; }

.returnIconWrapper {
  width: 10px;
  height: 16px;
  margin: 0 16px 0 0;

  @media screen and (max-width: $mobile) {
    width: 6px;
    height: 9px;
    margin: 0 9px 0 0; } }

.returnIcon {
  stroke: $pink;
  opacity: .6;
  width: 10px;
  height: 16px;

  @media screen and (max-width: $mobile) {
    width: 6px;
    height: 9px; } }

.close {
  margin: 0 0 0 auto; }

.closeIconWrapper {
  width: 13px;
  height: 13px;

  @media screen and (max-width: $mobile) {
    width: 11px;
    height: 11px; } }

.closeIcon {
  stroke: $pink;
  opacity: .2;
  width: 13px;
  height: 13px;

  @media screen and (max-width: $mobile) {
    width: 11px;
    height: 11px; } }
