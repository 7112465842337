@import "~styles/variables";
@import "~styles/store/variables";

.showCity {
    display: inline-block;
    position: relative;
    color: $pink;
    font-weight: bold;
    cursor: pointer;
    z-index: 2;

    & > span {
        display: inline-block;
        padding: 0 13px 0 0;
        background: url("~images/show_city.svg") no-repeat right 6px;
        position: relative; }

    &.open {
        & > span {
            background-image: url("~images/show_city_a.svg"); }

        @media screen and (max-width: $mobile) {
          color: $pink-dark;
          z-index: $overflow-all-z-index; }

        &:before {
            content: " ";
            display: block;
            width: 100%;
            height: 45px;
            padding: 0 20px;
            background: $white;
            position: absolute;
            top: -10px;
            left: -20px; } }

    @media screen and (max-width: $tablet) {
        font-size: 13px;

        &.open {
            &:before {
                padding: 0 10px;
                left: -10px; } } }

    @media screen and (max-width: $mobile) {
        &.open {
            &:before {
                padding: 0 20px;
                left: -20px; } } } }

.citySelect {
    background: white;
    color: $black;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    border-radius: 3px;
    text-align: left;

    @media screen and (max-width: $smallDesktop) {
        width: 1020px; }

    @media screen and (max-width: $tablet) {
        width: 760px; }

    @media screen and (max-width: $mobile) {
        margin-top: 89px;
        margin-left: 20px; }

    .topCityDrop {
        padding: 40px 20px 25px 20px;
        background: $white;
        overflow-x: auto;

        @media screen and (min-width: $mobile) {
          min-width: 540px; }

        @media screen and (max-width: $tablet) {
            padding: 20px 0;
            left: 0; }

        @media screen and (max-width: $mobile) {
            padding: 22px 20px 20px 20px; }

        .topSelectedCity {
            padding: 0 29px 28px 29px;
            line-height: 21px;

            span {
                padding: 0 8px 0 0;
                color: $black;
                font-weight: bold; }

            @media screen and (max-width: $tablet) {
                padding: 0 20px 20px 20px; }

            @media screen and (max-width: $mobile) {
                display: none; } }

        .citySearch {
            margin: 0 23px 23px 23px;

            @media screen and (max-width: $tablet) {
                margin: 0 20px 20px 20px; }

            @media screen and (max-width: $mobile) {
                margin: 0 0 15px 0; } }

        .topCityUl {
            padding: 0 9px;
            max-height: 53vh;

            li {
                margin: 0 0 6px 0;

                a {
                    display: flex;
                    flex-direction: column;
                    padding: 6px 20px;
                    color: $black;

                    &:hover {
                        background: $pink-light;
                        color: $pink; } } }

            .topCityB {
                font-weight: bold; }

            .topCityRegion {
                color: $grey;
                font-size: .9em; }

            @media (min-width: $mobile) {
                display: flex;
                flex-direction: column;
                line-height: 21px; }

            @media screen and (max-width: $tablet) {
                li {
                    margin: 0 0 4px 0;

                    a {
                        padding: 9px 20px; } } }

            @media screen and (max-width: $mobile) {
                padding: 0 4px 0 0;
                li {
                    margin: 0;
                    border-bottom: 2px solid $pink-light;

                    a {
                        padding: 9px 0; } } }

            overflow: auto;
            &::-webkit-scrollbar {
                width: 9px;
                height: 3px; }

            &::-webkit-scrollbar-button {
                display: none; }

            &::-webkit-scrollbar-track, &::-webkit-scrollbar-track-piece {
                background-color: $pink-light; }

            &::-webkit-scrollbar-thumb {
                height: 70px;
                background: linear-gradient(to right, $pink-light 33%, $pink-light 34%, $pink 35%, $pink 67%, $pink-light 68%, $pink-light);
                border-radius: 2px;
                cursor: pointer; }

            &::-webkit-scrollbar-corner, &::-webkit-resizer {
                background-color: $pink; } } } }

.manualInputContainer {
  padding: 0 80px 60px;

  @media screen and (max-width: $mobile) {
    padding: 0 30px 30px; }

  & .title {
      font: 400 30px/23px $font-secondary;
      color: $black;
      margin: 0 0 40px;
      text-align: center;

      @media screen and (max-width: $mobile) {
        font-size: 21px;
        line-height: 21px;
        margin: 0 0 14px;
        text-align: left; } }

  & form {
    margin: 0 80px;

    & .inputContainer {
      margin-bottom: 14px; }

    & .inputText {
      font-size: 15px;
      margin-bottom: 7px; }

    & .submitContainer {
      margin-top: 24px; }

    @media screen and (max-width: $mobile) {
      margin: 0; } } }

.manualMode {
  margin-top: 14px; }

.cityName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 18em;
  display: inline-block;

  @media screen and (max-width: $mobile) {
    max-width: 10em; } }
