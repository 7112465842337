// fonts
$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Archive', sans-serif;
$font-cur:'PT Sans', sans-serif;

// colors
$black: #2D0027;
$black-2: #231F20;
$gray-light: rgba(0, 0, 0, .1);
$blue: #00C2E1;
$blue-dark: #0079BF;
$blue-light: rgba(135,206,250, .2);
$green-light: #417690;
$grey: #967c92;
$pink: #A0148E;
$pink-2: #D866C9;
$pink-3: #d99dd1;
$pink-4: #EEC0E7;
$pink-5: #CF86C6;
$pink-6: #BA51AC;
$pink-7: #F5DFF1;
$pink-light: #FFF8FE;
$pink-light-2: #E8BCE2;
$pink-light-3: #F8E3F3;
$pink-light-4: #F1DAEE;
$pink-light-5: #E3CAE0;
$pink-light-6: #FAEDF8;
$pink-light-7: #C0B3BE;
$pink-light-8: #F0EDF0;
$pink-light-9: #EDE9ED;
$pink-light-10: #FAEEF9;
$pink-light-11: #AB95A8;
$pink-dark: #968093;
$pink-dark-2: #91798d;
$pink-dark-3: #7C4D76;
$pink-dark-5: #6C4D68;
$red: #EF2D2D;
$red-light: rgba(255, 0, 0, .2);
$violet-light: #f5def3;
$white: #ffffff;
$white-2: #f9eff7;
$white-3: #FFEFFD;
$white-shadow: #fbf2fa;
$white-shadow-2: #F7ECF5;
$white-shadow-3: #FFE3FB;
$white-shadow-4: #F3DFF1;
$white-background: rgba(255, 255, 255, .5);
$green: #27AE60;
$green-light-2: rgba(0, 255, 0, .2);
$gray: #828282;
$orange: #F2994A;

// breakpoints
$mediumDesktop: 1500px;
$smallDesktop: 1250px;
$tablet: 1020px;
$mobile: 759px;
$smallMobile: 530px;

// misc
$overflow-all-z-index: 10000;
$overflow-gallery-z-index: 11000;
