@import "~styles/store/variables.scss";

.number {
  font-size: 15px;
  font-weight: 700;
  color: $black;
  height: 20px;

  @media screen and (max-width: $mobile) {
    font-size: 12px; } }
