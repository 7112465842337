@import "~styles/store/variables.scss";

.orderedAt {
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px;
  font-size: 13px;
  line-height: 18px;
  background: $white;
  color: #A0148E;
  text-align: center;
  z-index: 10;
  border: 3px solid #F7ECF5;
  border-top: 0;
  border-left: 0;
  border-radius: 2px;
  font-size: 13px;
  font-weight: bold;
  text-transform: none;

  @media screen and (max-width: $mobile) {
    top: 10px;
    left: 12px;
    font-size: 11px;
    line-height: 14px;
    max-width: 60px; } }
