@import "~styles/store/variables.scss";

.repeatPrice {
  display: block;
  width: 157px;
  margin: 0;
  padding: 0;

  @media screen and (max-width: $mobile) {
    width: 100%; } }
