@import "variables";

@mixin default-text {
  font: 400 15px/23px $font-primary;
  transition: all 200ms;

  @media screen and (max-width: $mobile) {
    font: 400 12px/18px $font-primary; } }

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; }
  &:focus::-webkit-input-placeholder {
    color: transparent; }
  &:focus::-moz-placeholder {
    color: transparent; }
  &:focus:-moz-placeholder {
    color: transparent; }
  &:focus:-ms-input-placeholder {
    color: transparent; } }

@mixin default-text-input {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  border: 3px solid $white-2;
  padding: 8.5px 40px 10.5px 17px;
  margin: 0;
  border-radius: 3px;
  display: block;
  font-weight: normal;
  font-size: 15px;
  line-height: normal;
  font-family: $font-primary;
  color: rgba($pink, 0.4);
  text-align: left;
  background: $pink-light;
  transition: all 0.3s ease;

  @include placeholder {
    color: rgba($pink, 0.4);
    transition: all 0.3s ease; }

  @media screen and (max-width: $mobile) {
    padding: 7px 40px 9px 17px;
    font-size: 13px; }

  &:focus {
    outline: none;
    color: $black;
    border-color: rgba($black, 0.5);
    transition: all 0.3s ease; } }

@mixin btn-success {
  background-color: $green; }

@mixin btn-default {
  user-select: none;
  box-sizing: border-box;
  display: inline-block;
  font-family: $font-primary;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.02em;
  text-transform: none;
  color: $white;
  width: 100%;
  height: auto;
  background: $pink;
  border: none;
  line-height: 23px;
  padding: 10px 0 12px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  border-radius: 2px;
  text-align: center;
  transition: all 0.2s ease;

  @media screen and (max-width: $mobile) {
    font-size: 13px;
    line-height: 20px;
    padding: 11px 0 14px; }

  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: default;

    &:hover {
      opacity: 0.5; } }
  &:hover {
    background: $black;
    color: $white;
    transition: all 0.2s ease; } }

@mixin icon {
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain; }
