.notify_404 {
  margin-top: 45px;
  margin-bottom: 90px;
  width: 100%;
  height: auto;
  text-align: center;
  line-height: 80px;
  font-family: Archive;

  @media screen and ( max-width: $mobile) {
    margin-top: 15px;
    margin-bottom: 46px; }

  .number {
    font-size: 200px;
    padding-top: 45px;
    color: $white-shadow-2;

    @media screen and ( max-width: $mobile) {
      font-size: 100px;
      padding: 0; } }

  .hero_text {
    font-size: 50px;
    padding-top: 45px;
    color: $black;

    @media screen and ( max-width: $mobile) {
      font-size: 18px;
      line-height: 22px;
      padding-top: 5px; } }

  .back_to_main_btn {
    padding-top: 20px;

    @media screen and ( max-width: $mobile) {
      font-size: 13px;
      line-height: 20px;
      padding-top: 25px; }

    a {
      font-size: 22px;
      line-height: 33px;
      text-align: center;
      padding: 20px 40px;
      border: 3px solid #F7ECF5;
      border-radius: 3px;

      @media screen and ( max-width: $mobile) {
        font-size: 13px;
        line-height: 20px;
        padding: 13px 45px; } } } }
