@media screen and (max-width: $tablet) {
    .wrap {
        width: 760px; }

    .on760, .only760 {
        display: block; }

    .only980, .hide760 {
        display: none; }

    h1, .h1 {
        font-size: 40px;
        line-height: 48px; }

    h2, .h2 {
        font-size: 32px;
        line-height: 40px; }

    h3, .h3 {
        font-size: 28px;
        line-height: 34px; }

    header {
        padding: 10px 0 20px 0; }

    .top {
        padding: 0 10px 10px 10px; }

    .show_city {
        font-size: 13px;

        &.open:before {
            padding: 0 10px;
            left: -10px; } }

    .top_city_drop {
        padding: 20px 0;
        left: 0px; }

    .top_city_t {
        padding: 0 20px 20px 20px; }

    .city_search {
        margin: 0 20px 20px 20px; }

    .top_city_ul li {
        margin: 0 0 4px 0;

        a {
            padding: 9px 20px; } }

    .top_soc a {
        margin: 0 0 0 7px; }

    .top_nav ul li {
        padding: 0 10px 0 0;
        font-size: 13px; }

    .header {
        padding: 0 10px; }

    .logo {
        float: none;
        padding: 0;
        margin: 0 auto 10px auto; }

    .main_nav {
        padding: 2px 0 0 0; }

    .nav {
        width: 760px;
        top: 126px;
        left: 0px; }

    .top_search {
        width: 180px;

        &.open .search {
            top: 40px; } }

    .search_result {
        top: 105px;

        ul li a {
            padding: 9px 20px; } }

    .search_clear {
        padding: 15px 20px 0 20px; }

    .top_tel {
        font-size: 20px; }

    .top_icons > div {
        margin: 0 0 0 12px; }

    #content {
        padding: 0 10px 30px 10px; }

    .breadc {
        padding: 0 0 20px 0; }

    .prod_lc {
        width: 15%;
        padding: 10px 0;

        a {
            float: none;
            margin: 0 0 5px 0; } }


    .prod_lists_big .prod_list:nth-child(4n) {
        margin: 0 12px 12px 0; }

    .prod_lists_big .prod_list:nth-child(3n) {
        margin: 0 0 12px 0; }

    .prod_title {
        width: 85%;
        padding: 0; }

    .prod_l {
        width: 440px; }

    .prod_img_bigs {
        width: 360px; }

    .prod_imgs {
        width: 70px;

        &:before {
            width: 70px; }

        .slick-prev {
            left: 22px; }

        .slick-next {
            left: 22px; } }

    .prod_r {
        float: none;
        width: auto; }

    .prod_params {
        float: right;
        width: 284px;
        padding: 0 0 15px 0;
        font-size: 12px; }

    .prod_param {
        padding: 4px 0; }

    .prod_bi {
        float: right;
        width: 284px;
        padding: 0 0 15px 0; }

    .prod_by {
        margin: 0 0 10px 0;
        padding: 15px; }

    .prod_zab, .prod_dost {
        padding: 0 0 15px 35px; }

    .prod_colors_t {
        padding: 0 0 7px 0; }

    .prod_tabs {
        // padding: 30px 0 15px 0
        padding: 0px 0 15px 0; }

    .tab_pane {
        padding: 20px 0 0 0; }

    .relat_prods {
        padding: 0 0 15px 0; }

    .slick-dots {
        padding: 20px 0 0 0; }

    footer {
        padding: 30px 0 25px 0; }

    .nav_bot {
        width: 175px;
        margin: 0;
        padding: 0 10px 25px 10px; }

    .bot_apps {
        padding: 0 10px 25px 10px; }

    .bot_tel {
        padding: 0 10px 0 0; }

    .bot_soc {
        padding: 0 10px 0 0; }

    .bot_paykeeper {
        width: 190px;
        padding: 0 10px 0 0; }

    .copy {
        padding: 0 0 0 10px; } }
