@import "~styles/store/helpers.sass";

.goToPaymentButton {
  @include btn-default;
  padding: 10px 24px 12px;

  @media screen and (max-width: $mobile) {
    width: 100%;
    padding: 8px 0; } }

.small {
  width: 128px;
  font-size: 13px;
  padding: 2px 24px 3px;

  @media screen and (max-width: $mobile) {
    width: 100%; } }
