$backgroundGray: #f8f8f8;
$blue: #0083a7;
$borderGray: #e5e5e5;
$darkGray: #444;
$middleGray: #666;
$modalOverlayBackground: rgba(63, 7, 56, 0.2);
$operatorBlue: #0079bf;
$partnerBlue: #00c2e0;
$red: #e20013;
$violet: #a70083;
$overModalZIndex: 1030;
