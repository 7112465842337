@import "~styles/store/variables";
@import "~styles/store/helpers";

.content {
  padding: 10px 0; }

.bigTitle {
  font-size: 22px;
  line-height: 1;
  font-weight: bold;
  color: $white;

  @media screen and (max-width: $mobile) {
    font-size: 12px; } }

.title {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: $white;

  @media screen and (max-width: $mobile) {
    font-size: 12px;
    line-height: 16px; } }

.subTitle {
  font-size: 13px;
  line-height: 18px;
  color: $white;
  opacity: .5;
  margin-top: 8px;

  @media screen and (max-width: $mobile) {
    font-size: 11px;
    line-height: 15px; } }

.actions {
  display: flex;
  align-items: center;
  width: 100%; }


.action {
  @include btn-default;
  background: $white;
  color: $black;
  border-radius: 3px;
  padding: 10px 27px 12px;

  @media screen and ( max-width: $mobile) {
    padding: 10px 0; } }


