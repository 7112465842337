@import "~styles/store/variables.scss";
.hide {
  display: none; }

.navContainer {
  padding: 5px 0 10px 0;
  font-size: 15px;
  line-height: 40px;
  font-weight: bold;
  display: flex;
  justify-content: center;

  & li {
    margin: 0 5px 0 0;
    @media screen and (max-width: $smallDesktop) {
      margin: 0 5px 0 0; }
    @media screen and ( max-width: $mobile) {
      margin: 0 5px 0 0; }
    &:nth-last-child(2) {
      margin: 0; }

    a {
      display: inline-block;
      width: 40px;
      height: 40px;
      border: 3px solid #FFF8FE;
      border-radius: 3px;
      text-align: center;
      &:hover {
        background: #FFF8FE;
        color: #2D0027; }
      @media screen and (max-width: $smallDesktop) {
        width: 40px;
        height: 40px; }
      @media screen and ( max-width: $mobile) {
        width: 30px;
        height: 30px; } } }

  .active {
    a {
      background: #FFF8FE;
      color: #2D0027; }

    .dots {
      margin: 0 12px 0 -4px;
      color: #c0b3be;
      @media screen and (max-width: $smallDesktop) {
        margin: 0 8px 0 0; }
      @media screen and (max-width: $smallDesktop) {
        margin: 0 5px 0 0; } } }

  @media screen and (max-width: $smallDesktop) {
    padding: 10px 0 20px 0;
    font-size: 15px;
    line-height: 40px; }
  @media screen and ( max-width: $mobile) {
    padding: 8px 0 15px 0;
    font-size: 13px;
    line-height: 30px; } }
