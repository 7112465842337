@import "~styles/_variables";
@import "~styles/store/variables";
@import "~styles/store/_helpers";

.paymentContainer {
  float: right;
  width: 295px;
  box-shadow: 0 10px 25px $white-shadow-4;
  border-radius: 3px;
  background-color: $white;
  position: sticky;
  top: 0;

  @media (min-width: $tablet) {
    display: block !important; }

  @media screen and (max-width: $smallDesktop) {
    width: 260px; }

  @media screen and (max-width: $tablet) {
    float: none;
    width: auto;
    height: auto !important; } }

.payment {
  padding: 25px 35px 36px 35px;
  border-radius: 3px;
  color: $black;
  font-weight: bold;
  min-height: 100px;

  @media screen and (max-width: $smallDesktop) {
    padding: 30px 20px; }

  @media screen and (max-width: $tablet) {
    padding: 30px; }

  @media screen and (max-width: $mobile) {
    padding: 17px 24px 20px 24px; }

  label {
    cursor: pointer;

    .active {
      opacity: 0.7; }

    span {
      font-family: $font-primary;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 23px;
      color: #2D0027;
      opacity: 0.5;

      @media screen and (max-width: $mobile) {
        line-height: 20px; }

      span {
        display: block;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #2D0027;
        opacity: 0.7;

        @media screen and (max-width: $mobile) {
          font-size: 12px;
          line-height: 16px; } } } } }

.paymentLoading {
  display: flex;
  align-items: center;
  margin-top: 80px; }

.paymentMethod {
  margin-bottom: 14px; }

.paymentMethodDisabled {
  opacity: .4;
  cursor: not-allowed;

  & label {
    cursor: not-allowed; } }

.paymentMethodRadio {
  border-radius: 50%;
  vertical-align: -7px;
  width: 19px;
  height: 19px;
  margin: 0 5px 0 0;
  border: 3px solid $white-shadow-2;
  cursor: pointer;
  position: relative;
  display: inline-block;
  overflow: hidden;
  flex-shrink: 0; }

.paymentMethodRadioDisabled {
  cursor: not-allowed; }

.paymentMethodRadioInput {
  position: absolute;
  z-index: -1;
  opacity: 0;
  padding: 0;
  margin: 0;

  span {
    color: $black; } }

.paymentDescription {
  margin-left: 31px; }

.paymentTitle {
  float: left;
  padding: 0 0 20px 0;
  font-size: 22px;
  line-height: 34px;

  @media screen and (max-width: $smallDesktop) {
    width: 105px; }

  @media screen and (max-width: $mobile) {
    width: 106px;
    padding: 0 0 14px 0;
    font-size: 18px;
    line-height: 27px; } }

.taRight {
  text-align: right; }

.paymentTotalAmount {
  float: left;
  width: 142px;
  padding: 0 0 4px 0;
  color: $pink;
  font-size: 22px;
  line-height: 29px;
  text-transform: uppercase;
  white-space: nowrap;

  @media screen and (max-width: $smallDesktop) {
    width: 134px; }

  @media screen and (max-width: $tablet) {
    width: 243px; }

  @media screen and (max-width: $mobile) {
    width: 150px;
    padding: 0 0 14px 0;
    font-size: 18px;
    line-height: 27px; }

  .withoutDiscount {
    font-size: 15px;
    text-decoration: line-through;
    opacity: 0.3; } }

.paymentDetailLabel {
  float: left;
  width: 112px;
  padding: 0 10px 12px 0;
  line-height: 22px;
  opacity: 0.5;
  font-size: 15px;
  font-weight: normal;
  box-sizing: border-box;

  @media screen and (max-width: $smallDesktop) {
    width: 105px; }

  @media screen and (max-width: $mobile) {
    width: 106px;
    padding: 0 10px 7px 0;
    font-size: 13px; } }

.paymentSumDetailLabel {
  width: 80px; }

.paymentDetailValue {
  float: left;
  width: 110px;
  padding: 0 0 12px 0;
  line-height: 22px;
  font-size: 15px;

  @media screen and (max-width: $smallDesktop) {
    width: 110px; }

  @media screen and (max-width: $tablet) {
    width: 219px; }

  @media screen and (max-width: $mobile) {
    width: 125px;
    padding: 0 0 7px 0;
    font-size: 13px; } }

.deliveryInfoValue {
  display: flex; }

.radioInputChecked {
  width: 9px;
  height: 9px;
  margin: 5px 0 0 5px;
  border-radius: 50%;
  background: $pink; }

.clear {
  clear: both; }

.currency {
  font-family: $font-cur;
  font-size: .9em; }

.submitButton {
  display: inline-block;
  box-sizing: border-box;
  min-height: 45px;
  padding: 8px 0 10px;
  border: 3px solid $pink;
  background: $pink;
  border-radius: 3px;
  transition: all 0.3s 0.02s ease;
  -webkit-appearance: none;
  color: #fff;
  font-size: 15px;
  line-height: 21px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 100%;
  position: relative;

  @media screen and (max-width: $mobile) {
    width: 100%;
    min-height: 36px;
    padding: 7px 20px;
    font-size: 13px;
    line-height: 20px; }

  &:hover {
    border-color: #D866C9;
    background-color: #D866C9;
    color: #fff;
    text-decoration: none; } }

.submitButtonLoaderContainer {
  background: rgba(255, 255, 255, 0);
  transform: scale(.4) translate(0, 10%);

  @media screen and (max-width: $mobile) {
    transform: scale(.3) translate(0, -15%); } }

.submitButtonLoaderItem {
  & div {
    &:after {
      background-color: white; } } }

.disabled {
  opacity: .5;
  cursor: not-allowed; }

.divider {
  opacity: 0.4;
  height: 2px;
  width: calc(100% + 70px);
  background-color: $violet-light;
  margin: 4px 0 18px -35px;

  @media screen and (max-width: $smallDesktop) {
    width: calc(100% + 40px);
    margin: 4px 0 18px -20px; }

  @media screen and (max-width: $tablet) {
    width: calc(100% + 60px);
    margin: 4px 0 18px -30px; }

  @media screen and (max-width: $mobile) {
    width: calc(100% + 48px);
    margin: 4px 0 18px -24px; } }

.deliveryInfoContainer {
  display: inline-block;
  margin-left: 5px; }

.deliveryInfo {
  background-color: $white-shadow-2;
  color: $pink;
  opacity: .5;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    cursor: pointer; } }

.deliveryPopover {
  position: relative;
  margin-top: -10px;
  background-color: $white;
  box-shadow: 0 5px 26px rgba(160, 20, 142, 0.17);
  color: $black;
  padding: 10px 10px 10px 10px; }

.deliveryProduct {
  display: flex;
  justify-content: space-between; }

.deliveryProductTitle {
  opacity: .5;
  width: 150px;
  padding-right: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.deliveryProductCount {
  padding-right: 5px; }

.deliveryTotal {
  color: $pink; }

.deliveryPopoverCost {
  color: $pink;
  font-size: 15px;
  font-weight: bold; }

.deliveryPopoverArrow {
  position: absolute;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  bottom: -10px;
  left: 0;
  background: $white;
  right: 0;
  margin: auto; }

.sendSchedule {
  color: $pink-light-11;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  margin-bottom: 11px; }


.aside {
  @media screen and (min-width: $tablet) {
    flex-grow: 1;
    position: relative; } }

.asideInner {
  @media screen and (min-width: $tablet) {
    position: sticky;
    display: flow-root;
    top: 0; } }
