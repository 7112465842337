@import "~styles/_variables";
@import "~styles/store/variables";

.modalOverlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  background: $modalOverlayBackground;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.pageBody {
  position: relative; }

.noOutline {
  outline: none; }

.wrap {
  padding: 1rem; }

.alignItemsCenter {
  align-items: center !important; }

.alignItemsCenterDesktop {
  @media screen and (min-width: $mobile) {
    align-items: center !important; } }
