@import "~styles/variables";
@import "~styles/store/variables";

.search_history {
  background: $white;
  border-radius: 3px;
  margin-top: 20px;
  line-height: 21px;
  padding-bottom: 18px;
  @media screen and ( max-width: $mobile) {
    margin-top: 3px; }

  ul li {
    display: block;

    a {
      display: block;
      padding: 5px 0;
      color: $black;

      span {
        display: block;
        padding: 12px 29px;
        &:hover {
          background: $pink-light;
          color: $pink; } } }

    .clear_link {
      display: inline;
      color: lighten($red, 20);
      padding-left: 29px;
      &:hover {
        color: $red; } } } }

.prod_list_sm {
  width: 135px;
  height: 272px;
  margin: 0 20px 20px 0;
  padding: 25px 10px;

  @media screen and ( max-width: $smallDesktop) {
    width: 135px;
    margin: 0 10px 10px 0;
    padding: 25px 10px; }

  @media screen and ( max-width: $mobile) {
    width: calc(50% - 2px);
    margin: 0 2px 10px 0 !important; }


  &:nth-child(3n) {
    width: 135px;
    margin: 0 20px 20px 0;
    padding: 25px 10px;

    @media screen and ( max-width: $smallDesktop) {
      width: 135px;
      margin: 0 10px 10px 0;
      padding: 25px 10px; } }

  &:hover {
    border-color: #FCEBF9; }

   :global {
    .prod_list_img {
      height: 112px;
      margin: 0 0 9px 0;

      img {
        max-height: 112px; } }

    .prod_list_t {
      height: 68px;
      margin: 0;
      font-size: 13px;
      line-height: 17px; }

    .prod_list_bot {
      width: 130px;
      height: 55px;
      margin-left: -10px;
      background: #FFF8FE;
      position: unset;
      left: 0;
      bottom: 0;
      justify-content: space-between;

      @media screen and ( max-width: $mobile) {
        width: calc(100% + 20px); } }

    .prod_list_price {
      float: left;
      height: 45px;
      padding: 0 11px;
      margin: 0;
      line-height: 45px; }

    .prod_list_price_old {
      float: left;
      padding: 8px 11px 6px 11px;
      margin: 0; }

    .prod_list_nvn {
      height: 45px;
      padding: 0 12px;
      line-height: 45px;
      font-size: 10px; }

    .prod_list_add {
      float: right;
      height: 45px; }

    .btn_by_sm {
      border-color: transparent; }
    .img_placeholder {
      &-relative {
        height: 136px; } } } }

.mask {
  z-index: 10;
  background-color: $pink;
  opacity: 0.2;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

.loading {
  width: auto;
  height: auto;
  margin: 0;
  transform: translate(-50%, -50%) scale(.4); }

.loadingContainer {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 6.5px;
  top: 6.5px; }

.loadingPreloader {
  display: block;
  align-items: start;
  margin: 0;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto; }

.form {
  position: relative; }

.notFoundContainer {
  width: 100%;

  @media screen and ( max-width: $mobile) {
    padding: 12px 0 10px 15px; } }
