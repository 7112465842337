.order_info {
  text-align: center;
  font-size: 21px;
  margin-top: 21px;
  line-height: 27px;

  .mt-7 {
    margin-top: 7px; }

  .login_btn {
    margin: 61px 0;

    @media screen and (max-width: $mobile) {
      margin: 21px 0; }

    .top_user {
      width: auto; }

    a {
      font-size: 22px;
      line-height: 33px;
      text-align: center;
      padding: 20px 40px;
      border: 3px solid #F7ECF5;
      border-radius: 3px;

      @media screen and (max-width: $mobile) {
        font-size: 13px;
        line-height: 20px;
        padding: 13px 45px; } } } }
