.form_line {
    padding: 0 0 21px 0; }

.lab {
    display: block;
    padding: 0 0 14px 0;
    color: #2D0027;
    line-height: 23px;
    font-weight: bold; }

.inp {
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    padding: 7px 16px 9px 16px;
    background: #fff;
    background: $pink-light;
    border: 3px solid $white-2;
    transition: all 0.3s 0.02s ease;
    border-radius: 3px;
    -webkit-appearance: none;
    line-height: 23px;

    &:hover, &:focus {
        background-color: #FFEFFD;
        border-color: #F8E3F3; }

    &::placeholder {
        color: #d99dd1;
        transition: all 0.3s 0.02s ease; } }

.ta {
    box-sizing: border-box;
    width: 100%;
    height: 187px;
    padding: 7px 16px 9px 16px;
    background: #fff;
    background: $pink-light;
    border: 3px solid $white-2;
    transition: all 0.3s 0.02s ease;
    border-radius: 3px;
    -webkit-appearance: none;
    line-height: 23px;
    vertical-align: bottom; }

.form_sub {
    position: relative; }

.btn {
    display: inline-block;
    box-sizing: border-box;
    min-height: 45px;
    padding: 8px 26px 10px 26px;
    border: 3px solid $pink;
    background: $pink;
    border-radius: 3px;
    transition: all 0.3s 0.02s ease;
    -webkit-appearance: none;
    color: #fff;
    font-size: 15px;
    line-height: 21px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    cursor: pointer;

    &:hover {
        border-color: #D866C9;
        background-color: #D866C9;
        color: #fff;
        text-decoration: none; } }

.btn_s2 {
    font-size: 13px; }

.btn_c2 {
    border-color: #F7ECF5;
    background-color: #fff;
    color: $pink;

    &:hover {
        border-color: #D866C9;
        background-color: #D866C9;
        color: #fff; } }

.btn_c3 {
    border-color: #fde4e9;
    background-color: #fde4e9;
    color: #f79092;

    &:hover {
        border-color: #D866C9;
        background-color: #D866C9;
        color: #fff; } }

.form_sub .ajax-loader {
    position: absolute;
    top: -5px;
    right: -5px; }
