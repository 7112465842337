@font-face {
    font-family: 'Archive';
    src: url("../../fonts/Archive.eot");
    src: local("Archive"), url("../../fonts/Archive.eot?#iefix") format("embedded-opentype"), url("../../fonts/Archive.woff") format("woff"), url("../../fonts/Archive.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

// Open Sans (regular - 400, semibold - 600, bold - 700)
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
