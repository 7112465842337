.catalog {
    position: relative; }

.cat_prods {
    float: right;
    width: 899px; }

.filters {
    float: left;
    width: 284px;
    color: #2D0027;
    position: relative; }

.filters_title {
    padding: 0 0 22px 0;
    font-size: 22px;
    line-height: 33px;
    font-weight: bold; }

.hide_filter {
    display: none;
    padding: 15px 30px 0 30px;
    background: #FFF8FE;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;

    span {
        padding: 0 0 0 20px;
        background: url("../../images/hide_filter.svg") no-repeat 0px center;
        opacity: 0.6;
        transition: all 0.3s 0.02s ease;
        color: #A0148E; }

    &:hover span {
        opacity: 1; } }

.filter {
    padding: 14px 30px 7px 30px;
    background: #FFF8FE;
    border-bottom: 2px solid #FAEDF8;

    &:first-child {
        padding-top: 30px; } }

.filters form {
    position: relative; }

.filter_t {
    padding: 0 30px 0 0;
    margin: 0 0 5px 0;
    position: relative;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    font-weight: bold;

    &:before {
        content: " ";
        display: block;
        width: 14px;
        height: 9px;
        background: url("../../images/filter_t.svg") no-repeat;
        position: absolute;
        top: 6px;
        right: 0px;
        transition: all 0.3s 0.02s ease; }

    &:hover:before {
        background: url("../../images/filter_t_h.svg") no-repeat; } }

.close .filter_t:before {
    transform: rotate(180deg); }

.filter_cont {
    padding: 0 0 0 0;
    max-height: 400px;
    overflow: auto; }

.close .filter_cont {
    display: none; }

.filter_cb {
    padding: 0 0 5px 0;
    font-size: 13px;
    line-height: 22px;
    font-weight: bold;

    label {
        > div {
            float: left; }

        span {
            display: block;
            margin: 0 0 0 40px;
            padding: 2px 0 0 0;
            transition: all 0.3s 0.02s ease;
            cursor: pointer; }

        &:hover span {
            color: #A0148E; } } }

.filter_color {
    display: inline-block;
    box-sizing: border-box;
    width: 26px;
    height: 26px;
    border-radius: 2px;
    margin: 0 6px 10px 0;
    position: relative;
    vertical-align: bottom;
    cursor: pointer;

    &:after {
        content: " ";
        display: none;
        width: 26px;
        height: 26px;
        border: 3px solid #F1DAEE;
        border-radius: 2px;
        position: absolute;
        top: -3px;
        left: -3px;
        transition: all 0.3s 0.02s ease; }

    &.active:after {
        display: block; } }

.filter_color_clear {
    padding: 0 0 0 0;
    font-size: 11px;
    line-height: 11px;

    a {
        color: #EF2D2D;
        opacity: 0.7;

        &:hover {
            color: #EF2D2D;
            opacity: 1; } } }

.filter_brend {
    padding: 0 0 16px 0; }

.filter_brends {
    max-height: 319px;
    margin: 0 0 14px 0;
    overflow: auto; }

.filter_brends, .filter_cont {
    &::-webkit-scrollbar {
        width: 15px;
        height: 3px; }

    &::-webkit-scrollbar-button {
        display: none; }

    &::-webkit-scrollbar-track, &::-webkit-scrollbar-track-piece {
        background-color: #fff; }

    &::-webkit-scrollbar-thumb {
        height: 70px;
        background: linear-gradient(to right, #fff 33%, #fff 34%, #A0148E 35%, #A0148E 67%, #fff 68%, #fff);
        border-radius: 2px;
        cursor: pointer;
        border: 3px solid #fff; }

    &::-webkit-scrollbar-corner, &::-webkit-resizer {
        background-color: #A0148E; }

    li {
        padding: 6px 19px;
        background: #fff;
        border-bottom: 2px solid #FFF8FE;
        transition: all 0.3s 0.02s ease;
        color: #BA86B3;
        font-size: 13px;
        line-height: 20px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer; } }


.filter_bot {
    padding: 30px;
    background: #FFF8FE; }

.filter_go {
    padding: 0 0 10px 0; }

.filter_bot .btn {
    width: 100%; }

.filter_show {
    display: block;
    top: 440px;
    width: 115px;
    height: 56px;
    padding: 7px 0;
    background: #FFFFFF;
    box-shadow: 0px 5px 26px rgba(160, 20, 142, 0.17);
    border-radius: 3px;
    position: absolute;
    right: -140px;
    z-index: 500;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    box-sizing: border-box;

    &:before {
        content: " ";
        display: block;
        width: 14px;
        height: 100%;
        background: url("../../images/filter_show.svg") no-repeat;
        position: absolute;
        top: 0px;
        left: -14px; } }

.prod_lists {
    position: relative; }

.show_filter {
    float: left;
    display: none;
    height: 36px;
    padding: 0 16px 0 19px;
    margin: 0 0 15px 0;
    background: #FFF8FE;
    border-radius: 2px;
    cursor: pointer;
    font-size: 13px;
    line-height: 36px;
    font-weight: bold;

    span {
        padding: 0 20px 0 0;
        background: url("../../images/show_filter.svg") no-repeat right center;
        opacity: 0.6;
        transition: all 0.3s 0.02s ease;
        color: #A0148E; }

    &:hover span {
        opacity: 1; } }

.cat_order {
    min-height: 36px;
    padding: 11px 0 8px 0;
    color: #2D0027;
    font-size: 13px;
    line-height: 18px;
    min-width: 220px;
    text-align: right;

    a {
        margin: 0 0 0 18px; } }

.cat_act_up {
    padding: 0 11px 0 0;
    background: url("../../images/cat_act_up.svg") no-repeat right center;
    color: #2D0027;

    &:hover {
        background-image: url("../../images/cat_act_up_h.svg"); } }

.cat_act_down {
    padding: 0 11px 0 0;
    background: url("../../images/cat_act_down.svg") no-repeat right center;
    color: #2D0027;

    &:hover {
        background-image: url("../../images/cat_act_down_h.svg"); } }

.p_nav {
    padding: 16px 0 28px 0;
    font-size: 22px;
    line-height: 61px;
    font-weight: bold;

    a, .active {
        display: inline-block;
        width: 61px;
        height: 61px;
        margin: 0 16px 0 0;
        border: 3px solid #FFF8FE;
        border-radius: 3px;
        text-align: center; }

    a:hover, .active {
        background: #FFF8FE;
        color: #2D0027; }

    .dots {
        margin: 0 12px 0 -4px;
        color: #c0b3be; }

    a:last-child {
        margin: 0; } }

@media screen and (max-width: $smallDesktop) {
    .filters {
        width: 236px; }

    .filter {
        padding: 14px 16px 7px 16px; }

    .filter_bot {
        padding: 30px 16px; }

    .cat_prods {
        width: 732px; }

    .p_nav {
        padding: 10px 0 20px 0;
        font-size: 21px;
        line-height: 51px;

        a, .active {
            width: 51px;
            height: 51px;
            margin: 0 8px 0 0; }

        .dots {
            margin: 0 8px 0 0; }

        a:last-child {
            margin: 0; } } }

@media screen and (max-width: $tablet) {
    .filters {
        float: none;
        width: 284px;
        position: absolute;
        top: -7px;
        left: -15px;
        z-index: 9550;

        &.open {
            display: block; } }

    .filters_title {
        display: none; }

    .hide_filter {
        display: block; }

    .filter {
        padding: 14px 30px 7px 30px; }

    .filter_bot {
        padding: 30px; }

    .cat_prods {
        float: none;
        width: auto; }

    .show_filter {
        display: block; }

    .cat_order {
        float: right;
        min-height: 18px;
        padding: 9px 10px 20px 0; } }


@media screen and (max-width: $mobile) {
    .filters {
        left: -25px; }

    .filter_show {
        display: none; }

    .show_filter {
        margin-left: -19px; }

    .p_nav {
        padding: 8px 0 15px 0;
        font-size: 13px;
        line-height: 30px;

        a, .active {
            width: 30px;
            height: 30px;
            margin: 0 5px 0 0; }

        .dots {
            margin: 0 5px 0 0; }

        a {
            &:last-child {
                margin: 0; }

            &:nth-child(6n), &:nth-child(7n), &:nth-child(8n), &:nth-child(9n) {
                display: none; } } }


    .cat_order {
        padding-right: 0;
        a {
         margin: 0px 0 0 5px; } } }
