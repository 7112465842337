#content {
    padding: 0 0 50px 0; }

.breadc {
    padding: 0 0 31px 0;
    color: #C0B3BE;
    font-size: 13px;
    line-height: 18px;

    a {
        color: #C0B3BE;

        &:hover {
            color: #D866C9; } }

    .sep {
        padding: 0 4px; } }

.breadc_back {
    display: none;
    margin: 0 -19px 15px -19px;
    padding: 10px 20px;
    background: $pink-light;
    font-size: 15px;

    a {
        padding: 0 0 0 17px;
        background: url("~images/breadc_back.svg") no-repeat 0px center;
        opacity: 0.6;

        &:hover {
            opacity: 1;
            color: $pink; } }

    &.show_everywhere {
      display: block; } }

.prod_lc {
    float: right;
    padding: 14px 0;
    font-size: 11px;
    line-height: 18px;
    width: 20%;

    a {
        float: left;
        display: block;
        margin: 0 0 0 22px;
        padding: 1px 0 1px 29px;
        opacity: 0.6;

        &:hover, &.active {
            opacity: 1;
            color: $pink; } } }

.prod_like {
    background: url("~images/like.svg") no-repeat 0px (center / 22px) auto;
    cursor: pointer;

    &:hover, &.active {
        background-image: url("~images/top_like_h.svg");
        opacity: 1; } }

.prod_comp {
    background: url("~images/comp.svg") no-repeat 1px (center / 20px) auto;
    cursor: pointer; }

.prod_title {
    padding: 0 0 15px 0;
    left: 0;
    width: 80%;
    float: left;

    h1 {
      line-height: 44px;
      font-size: 40px; } }

.prod_l {
    float: left;
    width: 513px; }

.prod_img_bigs {
    float: right;
    width: 414px; }

.prod_img_big {
    a {
        display: block; }

    img {
        width: 260px;
        margin: 0 auto;
        vertical-align: bottom;
        border-radius: 3px;
        max-height: 500px;
        object-fit: contain; } }

.prod_imgs {
    float: left;
    width: 79px;

    &:before {
        content: " ";
        display: block;
        width: 79px;
        height: 22px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 100%);
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 100; } }

.slick-vertical .slick-slide {
    border: 0 !important; }

.prod_img {
    img {
        margin: 0 auto;
        vertical-align: bottom;
        border-radius: 3px;
        height: 60px; }

    span {
        display: block;
        margin: 0 0 20px 0;
        position: relative;
        cursor: pointer;

        &:before {
            content: " ";
            display: block;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(160, 20, 142, 0.4), rgba(160, 20, 142, 0.4));
            border-radius: 3px;
            position: absolute;
            top: 0px;
            left: 0px;
            transition: all 0.3s 0.02s ease;
            opacity: 0; }

        &:hover:before {
            opacity: 1; } } }

.edit-product-link {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' height='24' width='24' color='%23A0148E'%3E%3Cpath fill='currentColor' d='M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z'%3E%3C/path%3E%3C/svg%3E");
  height: 24px;
  width: 24px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (max-width: $mobile) {
    height: 18px;
    width: 18px; } }

.img_placeholder {
    &-relative {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 145px;
        left: 0;

        @media screen and (max-width: $mobile) {
            bottom: 110px;

            & .img_placeholder__text {
                font-size: 13px;
                line-height: 20px; } } }

    &-big {
        height: 500px; }

    & .img_placeholder__text {
        font-size: 22px;
        line-height: 33px; }

    &-small {
        width: 79px;
        height: 106px;

        @media screen and (max-width: $smallDesktop) {
            width: 60px; }

        @media screen and (max-width: $tablet) {
            width: 70px; }

        & .img_placeholder__text {
            font-size: 13px;
            line-height: 20px; } }

    &__text {
        font-weight: bold;
        font-size: 22px;
        line-height: 33px;
        color: $black;
        opacity: 0.2;
        text-align: center; }

    &__content {
        width: 100%;
        height: 95%;
        background-color: $pink-light;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center; }

    &__logo {
        width: 154px;
        height: 34px;
        background: url("~images/logo_l.svg") no-repeat center 6px;
        border-radius: 3px 3px 0 0;
        position: absolute;
        font-size: 9px;
        line-height: 11px;
        text-align: center;
        left: 20px;
        bottom: 15px; } }

.slick-current {
    z-index: $overflow-all-z-index;

    & span:before {
        opacity: 1;
        border: 3px solid $pink;
        background: none; } }

.prod_r {
    float: right;
    width: 653px;
    color: $black; }

.prod_params {
    float: left;
    width: auto;
    padding: 0 0 27px 0;
    position: relative; }

.prod_params_t {
    padding: 0 0 14px 0;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold; }

.prod_param {
    padding: 9px 0;
    border-bottom: 2px solid $pink-light;
    line-height: 20px;

    &:last-child {
        border: 0; } }

.prod_param_l {
    float: left;
    width: 140px;
    opacity: 0.5;

    @media screen and ( max-width: $tablet) {
        width: 100px; } }

.prod_param_r {
    float: right;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and ( max-width: $smallDesktop) {
        width: 150px; }

    @media screen and ( max-width: $tablet) {
        width: 180px;
        float: none; } }


.prod_params_show {
    display: none;
    width: 100%;
    padding: 30px 0 8px 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 69.27%);
    position: absolute;
    left: 0px;
    bottom: 0px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;

    span {
        padding: 0 14px 0 0;
        background: url("~images/prod_params_show.svg") no-repeat right 6px;
        opacity: 0.5;
        transition: all 0.3s 0.02s ease;
        color: $pink; }

    &.open span {
        background: url("~images/prod_params_show_o.svg") no-repeat right 6px; }

    span:hover {
        opacity: 1; } }

.prod_bi {
    float: right;
    width: 324px;
    padding: 0 0 27px 0; }

.prod_by {
    padding: 28px 20px 41px 20px;
    margin: 0 0 18px 0;
    background: $pink-light;
    border-radius: 3px;
    text-align: center; }

.prod_price {
    padding: 0 0 22px 0;
    font-size: 30px;
    line-height: 41px;
    font-weight: bold;
    display: flex;
    justify-content: center; }

.prod_price_old {
    color: $pink;

    .old {
        margin-left: 5px;
        opacity: 0.3;
        color: $black;
        font-size: 15px;
        line-height: 29px;
        text-decoration: line-through; }

    .discount {
        background: $pink;
        color: #fff;
        font-size: 12px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px; } }

.arrive_expected_at {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 22px; }

.btn_by {
    display: inline-block;
    box-sizing: border-box;
    width: 210px;
    min-height: 67px;
    padding: 19px 20px 21px 20px;
    background: $pink;
    border-radius: 3px;
    transition: all 0.3s 0.02s ease;
    color: #fff;
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
    text-align: center;
    border: 0;

    &:hover {
        background-color: #D866C9;
        color: #fff; }

    span {
        padding: 7px 0 10px 45px;
        background: url("~images/btn_by.svg") no-repeat 0px center; }

    &.disable {
        opacity: 0.4;
        cursor: not-allowed;

        span {
            opacity: 0.4; }

        &:hover {
            background-color: $pink; } } }

.prod_ok {
    width: 210px;
    margin: 0 auto;
    position: relative; }

.prod_ok_go {
    display: block;
    box-sizing: border-box;
    height: 67px;
    padding: 12px 68px 0 5px;
    background: $pink;
    border-radius: 3px;
    color: #fff;
    font-size: 15px;
    line-height: 21px;
    font-weight: bold;

    span {
        display: block;
        opacity: 0.5;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400; }

    &:hover {
        background: #D866C9;
        color: #fff; } }

.btn_plus {
    display: block;
    width: 61px;
    height: 61px;
    background: $pink-light;
    border-radius: 2px;
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 15px;
    line-height: 63px;
    font-weight: bold; }

.prod_zab {
    padding: 0 0 20px 35px;
    background: url("~images/prod_zab.svg") no-repeat 2px 0px; }

.prod_dost {
    padding: 0 0 20px 35px;
    background: url("~images/prod_dost.svg") no-repeat 0px 2px; }

.prod_colors_t {
    padding: 0 0 15px 0;
    font-size: 22px;
    line-height: 23px;
    font-weight: bold; }

.prod_color {
    float: left;
    width: 61px;
    height: 81px;
    padding: 10px 15px 0 0;

    a {
        display: block;
        position: relative;

        &:before {
            content: " ";
            display: block;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(160, 20, 142, 0.4), rgba(160, 20, 142, 0.4));
            border-radius: 3px;
            position: absolute;
            top: 0px;
            left: 0px;
            transition: all 0.3s 0.02s ease;
            opacity: 0; }

        &:hover:before {
            opacity: 1; } }

    &.active a:before {
        opacity: 1;
        border: 3px solid $pink;
        background: none; }

    img {
        vertical-align: bottom;
        border-radius: 3px; } }

.prod_tabs {
    // padding: 67px 0 26px 0
    padding: 0 0 26px 0; }

.tabs li {
    display: inline-block;
    margin: 0 66px 0 0;
    vertical-align: bottom;
    font-size: 22px;
    line-height: 33px;
    font-weight: bold;

    &:last-child {
        margin: 0; }

    a {
        display: inline-block;
        padding: 0 0 14px 0;
        position: relative;
        opacity: 0.5;
        color: $black;

        &:hover {
            color: $pink; } }

    &.ui-tabs-active a {
        opacity: 1;
        color: $black; }

    a {
        &:before {
            content: " ";
            display: block;
            width: 0;
            height: 2px;
            background: #e3cae0;
            position: absolute;
            bottom: 0px;
            left: 0px;
            transition: all 0.3s 0.02s ease; }

        &:hover:before {
            width: 100%; } }

    &.ui-tabs-active a:before {
        width: 100%;
        background: $black; }

    a {
        span {
            display: inline-block;
            min-width: 16px;
            height: 24px;
            padding: 0 4px;
            border: 2px solid #f0edf0;
            border-radius: 13px;
            transition: all 0.3s 0.02s ease;
            font-size: 15px;
            line-height: 24px;
            text-align: center; }

        &:hover span {
            border-color: #f0d9ed; } }

    &.ui-tabs-active a span {
        border-color: $pink-light;
        background: $pink-light; } }

.tab_pane {
    padding: 34px 0 0 0;
    margin: (-2px) 0 0 0;
    border-top: 2px solid #f0edf0; }

.prod_text {
    position: relative; }

.prod_text_show {
    display: none;
    width: 100%;
    padding: 30px 0 8px 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 69.27%);
    position: absolute;
    left: 0px;
    bottom: 0px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;

    span {
        padding: 0 14px 0 0;
        background: url("~images/prod_params_show.svg") no-repeat right 6px;
        opacity: 0.5;
        transition: all 0.3s 0.02s ease;
        color: $pink; }

    &.open span {
        background: url("~images/prod_params_show_o.svg") no-repeat right 6px; }

    span:hover {
        opacity: 1; } }

.prod_rew {
    padding: 0 0 19px 0;
    margin: 0 0 19px 0;
    border-bottom: 1px solid #fbf2fa;
    color: $black; }

.prod_rew_lv2 {
    margin-left: 70px; }

.prod_rew_lv3 {
    margin-left: 140px; }

.prod_rew p {
    margin: 0 0 5px 0; }

.prod_rew_a {
    padding: 0 0 13px 0;
    font-weight: bold; }

.prod_rew_d {
    padding: 0 0 12px 0;
    opacity: 0.5;
    line-height: 23px; }

.prod_rew_repl {
    padding: 5px 0; }

.prod_rew_add {
    padding: 12px 0 28px 0; }

.relat_prods {
    padding: 0 0 30px 0;

    .title {
        padding: 0 0 4px 0; } }

.prods_slider {
    margin: 0 -12px; }

.slick-arrow {
    display: block;
    width: 46px;
    height: 45px;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    z-index: 100;
    transform: translateY(-50%);
    overflow: hidden;
    text-indent: -9999px;
    transition: all 0.3s 0.02s ease;
    cursor: pointer; }

.prods_slider .slick-arrow {
    transform: translateY(-100%); }

.slick-prev {
    background: $pink-light url("~images/prev.svg") no-repeat (center / 14px) auto;
    left: 12px; }

.slick-next {
    background: $pink-light url("~images/next.svg") no-repeat (center / 14px) auto;
    right: 11px; }

.slick-arrow:hover {
    background-color: #FCEBF9; }

.prod_imgs {
    .slick-prev {
        top: -20px;
        left: 28px;
        width: 26px;
        height: 15px;
        background: url("~images/prod_imgs_prev.svg") no-repeat;

        &:hover {
            background: url("~images/prod_imgs_prev_h.svg") no-repeat; } }


    .slick-next {
        width: 26px;
        height: 15px;
        background: url("~images/prod_imgs_next.svg") no-repeat;
        top: auto;
        left: 28px;

        &:hover {
            background: url("~images/prod_imgs_next_h.svg") no-repeat; } } }

.slick-dots {
    height: 17px;
    padding: 32px 0 0 0;
    line-height: 14px;
    text-align: center;

    li {
        display: inline-block;
        vertical-align: middle;
        padding: 0 5px;

        button {
            display: inline-block;
            vertical-align: middle;
            width: 10px;
            height: 10px;
            padding: 0;
            margin: 0;
            border: 0;
            background: $pink;
            border-radius: 50%;
            opacity: 0.15;
            overflow: hidden;
            text-indent: -9999px;
            cursor: pointer;
            transition: all 0.3s 0.02s ease;

            &:hover {
                opacity: 1; } }

        &.slick-active button {
            width: 13px;
            height: 13px;
            opacity: 1; } } }

@media (max-width: 1260px) {
    .prods_slider {
        margin: 0; }

    .slick-prev {
        left: 0px; }

    .slick-next {
        right: 0px; } }

.prod_list {
    float: left;
    box-sizing: border-box;
    width: 283px;
    height: 412px;
    margin: 0 25px 15px 0;
    padding: 28px 24px;
    border: 3px solid $pink-light;
    border-radius: 3px;
    position: relative;
    transition: all 0.3s 0.02s ease;
    text-align: center;

    .discount {
        background: $pink;
        color: white;
        position: absolute;
        top: 2px;
        left: 2px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center; }

    @media screen and (max-width: $mobile) {
        width: 135px;
        height: 255px;
        margin: 0 10px 10px 0;
        padding: 25px 10px; }

    &:hover {
        border-color: #FCEBF9; }

    &.product_list_purchased {
            height: 502px; } }

.prod_lists_big .prod_list:nth-child(3n) {
	  margin: 0 25px 15px 0; }

.prod_lists_big .prod_list:nth-child(4n) {
	  margin: 0 0 15px 0; }

.prod_list:nth-child(3n) {
    margin: 0 0 15px 0;

    @media screen and (max-width: $mobile) {
        width: 135px;
        height: 255px;
        margin: 0 10px 10px 0;
        padding: 25px 10px; } }

.prods_slide .prod_list {
    float: none;
    margin: 0 auto; }

.prod_list_img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 226px;
    margin: 0 0 23px 0;

    img {
        max-height: 226px;
        vertical-align: bottom; } }

.prod_list_t {
    height: 55px;
    margin: 0 0 15px 0;
    overflow: hidden;
    font-weight: bold;
    line-height: 20px;
    font-size: 13px;

    a {
        color: $black;

        &:hover {
            color: #D866C9; } } }

.prod_list_price {
    display: inline-block;
    vertical-align: bottom;
    height: 46px;
    padding: 0 16px;
    margin: 0 10px 0 0;
    background: $pink-light;
    border-radius: 2px;
    color: $black;
    font-size: 15px;
    line-height: 46px;
    font-weight: bold; }

.prod_list_price_old {
    display: inline-block;
    height: 31px;
    padding: 9px 16px 6px 16px;
    margin: 0 10px 0 0;
    background: $pink-light;
    border-radius: 2px;
    color: $pink;
    font-size: 15px;
    line-height: 17px;
    font-weight: bold;
    .old {
        display: block;
        opacity: 0.3;
        color: $black;
        font-size: 11px;
        line-height: 13px;
        text-decoration: line-through; } }

.prod_list_add {
    display: inline-block;
    height: 46px;
    vertical-align: bottom; }

.btn_by_sm {
    display: block;
    width: 39px;
    height: 39px;
    background: url("~images/btn_by_sm.svg") no-repeat center;
    border: 3px solid #F7ECF5;
    border-radius: 2px;
    font-size: 0;
    color: transparent;

    &:hover {
        background: #FFE3FB url("~images/btn_by_sm_h.svg") no-repeat center;
        border-color: #FFE3FB; } }

.prod_list_comp {
    display: block;
    width: 20px;
    height: 16px;
    background: url("~images/top_comp.svg") no-repeat (center / 20px) auto;
    position: absolute;
    top: 16px;
    right: 19px;
    opacity: 0.3;
    font-size: 0;
    color: transparent;

    &:hover, &.active {
        background-image: url("~images/top_comp_h.svg");
        opacity: 1; } }

.prod_list_like {
    display: block;
    width: 22px;
    height: 19px;
    background: url("~images/top_like.svg") no-repeat (center / 20px) auto;
    position: absolute;
    top: 13px;
    right: 52px;
    opacity: 0.3;
    font-size: 0;
    color: transparent;

    &:hover, &.active {
        background-image: url("~images/top_like_h.svg");
        opacity: 1; } }

.post_list {
    padding: 0 0 20px 0; }

.post_list_t {
    padding: 0 0 3px 0;
    font-weight: bold; }

.prod_list_nvn {
    display: inline-block;
    vertical-align: bottom;
    height: 46px;
    padding: 0 27px;
    background: $pink-light;
    border-radius: 2px;
    color: #ab95a8;
    font-size: 13px;
    line-height: 46px;
    font-weight: bold;

    @media screen and (max-width: $mobile) {
        height: 45px;
        padding: 0 12px;
        line-height: 45px; } }

.post {
    padding: 0 0 10px 0; }

.modal-gallery {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.prod_list_bot {
  display: flex;
  align-items: center;
  justify-content: center; }

.product_bottom_string {
  color: $pink-light-7; }
